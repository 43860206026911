import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple} from "@fortawesome/free-brands-svg-icons";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {signInWithAppleRequest} from "../../reducers/authSlice";
import config from "../../config";

const SocialLogin = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');

        // Set the source to the remote script URL
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

        // Optional: Set script attributes like async or defer
        script.async = false;

        // Append the script to the document body
        document.body.appendChild(script);

        // Clean up the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, [])
    function handleAppleSignIn() {
        window.AppleID.auth.init({
            clientId: config.appleId,
            scope: "email name",
            redirectURI: config.appleRedirectURI, // maybe it is not useful when using popup
            state: JSON.stringify({"redirect_path": "/"}),
            usePopup: true,
        });
        window.AppleID.auth
            .signIn()
            .then(async (response) => {
                console.log(response)
                dispatch(signInWithAppleRequest(response));
            })
            .catch((error) => {
                console.error(error);
            });
    }
    return (
        <>
            <div className="rounded-t mb-0 py-6">
                <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold"><FormattedMessage id="common.sign.in.with"/></h6>
            </div>
            <div className="btn-wrapper text-center">
                {/*<button onClick={() => Auth.federatedSignIn({provider: 'Google'})}*/}
                {/*        className="bg-gray-700 text-white my-2 w-5/6 sm:w-2/6 px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out"*/}
                {/*        type="button">*/}
                {/*    <FontAwesomeIcon icon={faGoogle} className="mr-2" />*/}
                {/*    <FormattedMessage id="label.google"/>*/}
                {/*</button>*/}
                <button onClick={() => handleAppleSignIn()}
                        className="bg-gray-700 text-white my-2 w-5/6 sm:w-2/6 px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out"
                        type="button">
                    <FontAwesomeIcon icon={faApple} className="mr-2" />
                    <FormattedMessage id="label.apple"/>
                </button>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300"/>
        </div>
        </>
    )
}

export default SocialLogin;