import React, {useState} from "react";
import { FormattedMessage } from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import CenteredModal from "../tools/CenteredModal";
import IngenicoContainer from "../../containers/IngenicoContainer";
import {NewCardType} from "../../structs/apiConstants";

const ProfileBank = (props) => {
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        props.refreshUserProfile();
        setOpenModal(false)
    };

    // We remove 1 millisecond to cardDate to display the real expirationDate (2024-07-01 00:00:00 -> 2024-06-30 23:59:59)
    const dateLabel = (cardDateInput) => {
        let cardDate = new Date(cardDateInput);
        cardDate = new Date(cardDate.getTime() - 1);
        const month = cardDate.getUTCMonth() + 1;
        const cardMonth = month < 10 ? `0${month}` : `${month}`;
        return `${cardDate.getUTCFullYear()}-${cardMonth}`;
    }

    return (
        <>
            <CenteredModal
                openModal={openModal}
                onCloseModal={closeModal}
            >
                <IngenicoContainer type={NewCardType} amount={2}/>
            </CenteredModal>
            <h2 className="text-xl dark:text-gray-300 font-extrabold capitalize">
                <FormattedMessage id="common.credit.card"/>
            </h2>

            {props.cards.map(card =>
                <div key={card.id}>
                    <div className="inline-block w-3/5 pr-4 mb-4">
                        <input type="text" id="payment"
                               className="bg-gray-200 border border-gray-300 rounded py-2 px-2 block w-full appearance-none text-xs"
                               placeholder={`${card.description} - ${dateLabel(card.date)}`} disabled/>
                    </div>
                    <div className="inline-block w-2/5 pr-1 text-green-500">
                        <label className="block text-sm font-bold mb-3">
                            <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>
                            <FormattedMessage id="label.expire.on"/> {dateLabel(card.date)}
                        </label>
                    </div>
                </div>
            )}

            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                <FormattedMessage id="label.add.cb"/>
            </Button>
        </>
    )
};

export default ProfileBank;