import React, {useState} from "react";
import PropTypes from 'prop-types';
import Passeport from "../../Passeport";
import TextField from "@material-ui/core/TextField";
import PhoneField from "../tools/PhoneField";
import Button from "@material-ui/core/Button";

import SelectCountry from "../tools/SelectCountry";

import {FormattedMessage, useIntl} from "react-intl";
import Cgv from "../Cgv";
import {checkAndSetCanValidateStep} from "../../HelperFunctions";
import SelectCard from "../tools/SelectCard";
import config from "../../config";

const countries = {
    fr: {...require('../../translations/country/fr')},
    en: {...require('../../translations/country/en')},
    it: {...require('../../translations/country/it')},
    de: {...require('../../translations/country/de')},
    es: {...require('../../translations/country/es')},
    zh: {...require('../../translations/country/zh')},
};

const InfoReminder = props => {
    const intl = useIntl();
    const [inputs, setInputs] = useState({});
    const mandatoryFields = ["cgv", "cgu", "cgd"];

    const onChangeInfo = e => {
        const newInputs = {...inputs, [e.target.name]: e.target.value.trim() };
        setInputs(newInputs);
        props.updateForm(e);
    };

    const shouldShowModify = () => {
        const { address, zipCode, city, country, phone} = props.userProfile;
        return (
            (inputs.address && inputs.address !== "" && inputs.address !== address) ||
            (inputs.zipCode && inputs.zipCode !== "" && inputs.zipCode !== zipCode) ||
            (inputs.city && inputs.city !== "" && inputs.city !== city) ||
            (inputs.country && inputs.country !== "" && inputs.country !== country) ||
            (inputs.phone && inputs.phone !== "" && inputs.phone !== phone)
        )
    };

    const onChangeCgv = (e) => {
        const newInputs = {...inputs, [e.target.name]: e.target.checked };
        setInputs(newInputs);
        checkAndSetCanValidateStep(newInputs, mandatoryFields, props.canValidateStep, props.setCanValidateStep)
    };

    const additionnalAddress = props.userProfile.additionalAddress ? ` - ${props.userProfile.additionalAddress}` : "";
    const state = props.userProfile.state ? ` - ${props.userProfile.state}` : "";
    const country = countries[intl.locale][props.userProfile.country];
    const address = `${props.userProfile.address}${additionnalAddress} - ${props.userProfile.zipCode} ${props.userProfile.city}${state} - ${country}`;

    return <>
        {!props.userProfile.professional ? //Particulier
            <>
                <span className="inline-block w-full text-sm text-capitalize capitalize">
                    <FormattedMessage id="label.reminder.nom"/>{props.userProfile.firstName} {props.userProfile.lastName}
                </span>
                <span className="inline-block w-full text-sm text-capitalize">
                    <FormattedMessage id="label.reminder.telephone"/>+{props.userProfile.phone}
                </span>
                <span className="inline-block w-full text-sm text-capitalize">
                    <FormattedMessage id="label.reminder.address"/>{address}
                </span>
            </>
            : //Professionnel
            <>
                <span className="inline-block w-full text-sm text-capitalize">
                    <FormattedMessage id="label.reminder.company"/>{props.userProfile.company} - {props.userProfile.siret} - <span className="uppercase">{props.userProfile.interVAT}</span>
                </span>
                <span className="inline-block w-full text-sm text-capitalize capitalize">
                    <FormattedMessage id="label.reminder.ceo"/>{props.userProfile.firstName} {props.userProfile.lastName}
                </span>
                <span className="inline-block w-full text-sm text-capitalize">
                    <FormattedMessage id="label.reminder.address"/>{address}
                </span>
            </>
        }
        <div className="w-full mt-4 pl-1 w-100">
            <br/><Cgv onChange={onChangeCgv} saleConditions={props.saleConditions} cpName={props.cpName} cpType={props.cpType}/>
        </div>
    </>
};

InfoReminder.propTypes = {
    userProfile: PropTypes.instanceOf(Passeport),
    cpName: PropTypes.string,
    saleConditions: PropTypes.string,
    email: PropTypes.string,
    saveProfileRequest: PropTypes.func,
};

export default InfoReminder;