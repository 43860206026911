import React, {useState} from 'react';
import Input from "./tools/Input";
import {FormattedMessage} from "react-intl";
import config from "../config";
import {Link} from "react-router-dom";

const ResetPassword = (props) => {


    const onClick = () => {
        props.confirmForgotPasswordRequest();
    };

    return (
        <div className="py-6">
            <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto">
                <div className="hidden lg:block lg:w-1/2 bg-gray-400 shadow-lg p-10 rounded-lg overflow-hidden">
                    <div
                        className={`${config.isMoniteur ? "bg-moniteur" : "bg-drouot-3"} bg-no-repeat bg-contain max-w-sm md:max-w-lg h-60 mb-8`}/>
                    <h2 className="text-white text-3xl font-semibold"><FormattedMessage id="welcome.back"/></h2>
                    <p className="xs:hidden text-white text-md mt-2">
                        <FormattedMessage id="info.mdp.info.choisir.new.psw"/>
                    </p>
                </div>

                <div className="w-full p-8 lg:w-1/2">
                    <h2 className="text-2xl font-bold text-center">
                        <FormattedMessage id="common.reset.mdp"/>
                    </h2>
                    <div className="mt-4">
                        <div className="flex justify-between">
                            <label className="block text-sm font-bold mb-2"><FormattedMessage
                                id="common.nouveau.mot.de.passe"/></label>
                        </div>
                        <Input
                            name="password"
                            type="password"
                            placeholder="password"
                            onChange={props.onChange}/>
                    </div>
                    <div className="w-full pt-2">
                        <p>
                            <FormattedMessage
                                id="password.create.rule"/>
                        </p>
                    </div>

                    <div className="mt-6">
                        <button onClick={onClick}
                                className="w-full bg-red-600 text-white text-center text-uppercase px-8 py-3 rounded font-weight-bold hover:bg-red-800 transition">
                            <FormattedMessage id="common.confirm"/>
                        </button>
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                        <span className="border-b w-1/5 md:w-1/4"></span>
                        <Link to="/">
                            <button className="text-xs text-gray-500 uppercase"><FormattedMessage
                                id="common.back.to.sign.in"/></button>
                        </Link>
                        <span className="border-b w-1/5 md:w-1/4"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;