import {FormattedMessage} from "react-intl";
import React from "react";

const ProfileData = (props) => {
    return (
        <>
            <div className="inline-block my-2 w-3/6 sm:w-2/6 pr-1 text-gray-500"><label><FormattedMessage id={props.label}/></label></div>
            <div className="inline-block my-2 pl-1 w-3/6 sm:w-4/6 text-right sm:text-left">{props.value}</div>
        </>
    )
};

export default ProfileData