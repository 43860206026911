import ConfirmPhoneContainer from "../../containers/ConfirmPhoneContainer";
import InfoReminder from "./InfoReminder";
import React from "react";
import Cgv from "../Cgv";
import ProfileBank from "../profile/ProfileBank";
import ProfileNameAdress from "../profile/ProfileNameAdress";
import ConfirmSignUpContainer from "../../containers/ConfirmSignUpContainer";
import {confirmPhoneRequest, confirmSignUpRequest, saveProfileRequest,} from "../../reducers/authSlice";
import CongratulationContainer from "../../containers/CongratulationContainer";
import SumSubContainer from "../../containers/SumSubContainer";
import {submitDocumentRequest} from "../../reducers/documentSlice";
import DocumentSubmitContainer from "../../containers/DocumentSubmitContainer";
import IngenicoContainer from "../../containers/IngenicoContainer";
import {createRegistrationForSaleRequest} from "../../reducers/registrationSlice";


export const infoReminderStep = (saleId, userProfile, email, saleConditions, cpName, cpType, saveProfileRequest, createRegistrationFlag = true) => ({
    stepTitle: "step.info.reminder.step.title",
    windowTitle: "step.info.reminder.window.title",
    subtitleId: "step.info.reminder.subtitle",
    TextId: "step.info.reminder.text",
    component: <InfoReminder userProfile={userProfile} email={email} saleConditions={saleConditions} cpName={cpName} cpType={cpType} saveProfileRequest={saveProfileRequest}/>,
    validatorAction: createRegistrationFlag ? () => createRegistrationForSaleRequest(saleId) : null,
    isOptional: false,
});

export const confirmPhoneStep = () => ({
    stepTitle: "step.confirm.phone.step.title",
    windowTitle: "step.confirm.phone.window.title",
    //subtitleId: "step.confirm.phone.subtitle",
    TextId: "step.confirm.phone.text",
    windowText: "registration.bid.reminder",
    component: <ConfirmPhoneContainer isTunnel/>,
    isOptional: false,
    validatorAction: confirmPhoneRequest,
    validatorButtonId: "step.confirm.phone.button"
});

export const cgvStep = (saleId, conditions, cpName, createRegistrationFlag = true) => ({
    stepTitle: "step.cgv.step.title",
    windowTitle: "step.cgv.window.title",
    subtitleId: "step.cgv.subtitle",
    TextId: "step.cgv.text",
    component: <Cgv saleConditions={conditions} cpName={cpName}/>,
    validatorAction: createRegistrationFlag ? () => createRegistrationForSaleRequest(saleId) : null,
    isOptional: false,
});

export const sumsubStep = (saleId) => ({
    stepTitle: "step.docs.step.title",
    windowTitle: "step.docs.window.title",
    subtitleId: "step.docs.subtitle",
    TextId: "step.docs.text",
    component: <SumSubContainer saleId={saleId} isTunnel/>,
    isOptional: true,
});

export const GreenPointDocStep = (saleId) => ({
    stepTitle: "step.greepoint.docs.step.title",
    windowTitle: "step.greepoint.docs.window.title",
    subtitleId: "step.greepoint.docs.subtitle",
    TextId: "step.greepoint.docs.text",
    component: <DocumentSubmitContainer saleId={saleId} isTunnel/>,
    validatorAction: submitDocumentRequest,
    isOptional: true,
});

export const personalInfoStep = (currentUserProfile) => ({
    stepTitle: "step.personal.info.step.title",
    windowTitle: "step.personal.info.window.title",
    subtitleId: "step.personal.info.subtitle",
    TextId: "step.personal.info.text",
    component: <ProfileNameAdress isTunnel={true}/>,
    validatorAction: saveProfileRequest,
    isOptional: false,
});

export const cardForm = () => ({
    stepTitle: "step.card.form.step.title",
    windowTitle: "step.card.form.window.title",
    subtitleId: "step.card.form.subtitle",
    TextId: "step.card.form.text",
    component: <ProfileBank/>,
    isOptional: true,
});

export const confirmMailStep = () => ({
    stepTitle: "step.confirm.mail.step.title",
    windowTitle: "step.confirm.mail.window.title",
    subtitleId: "step.confirm.mail.subtitle",
    TextId: "step.confirm.mail.text",
    component: <ConfirmSignUpContainer/>,
    validatorAction: confirmSignUpRequest,
    isOptional: false,
    validatorButtonId: "step.confirm.mail.button"
});

export const congratulationProfileStep = () => ({
    stepTitle: "step.congratulation.profile.step.title",
    windowTitle: "step.congratulation.profile.window.title",
    component: <CongratulationContainer/>,
});

export const paymentStep = (type, amount) => ({
    stepTitle: "step.payment.step.title",
    windowTitle: "step.payment.window.title",
    subtitleId: "step.payment.subtitle",
    TextId: "step.payment.text",
    component: <IngenicoContainer type={type} amount={amount} isTunnel/>,
});