import {call, takeLatest, select, put} from 'redux-saga/effects'
import {
    selectFormInputs,
    submitDocumentRequest,
    submitDocumentSuccess,
    documentError, selectSaleId,
} from "../reducers/documentSlice";
import {selectUser, selectCurrentUserProfileField, updateSingleUserField} from "../reducers/authSlice";
import {stepSucceeded} from "../reducers/tunnelSlice";
import config from "../config";
import {KBIS, REGISTER} from "../structs/apiConstants";
import {getOdinCookie} from "./authSagas";

function* submitDocumentSaga() {
    const data = yield select(selectFormInputs);
    const saleId = yield select(selectSaleId);
    const currentDocs = yield select(selectCurrentUserProfileField, "documents");
    try {
        const formData = new FormData();
        formData.append("idDoc", data.idDoc, data.idDoc.name);
        formData.append("country", data.country);
        formData.append("docType", data.docType);
        formData.append("saleId", saleId);
        const accessToken = getOdinCookie("access")

        const requestConfig = {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        };
        const resp = yield call(fetch, config.gingolemApi + '/iddocument/submit', requestConfig);
        if (resp.status !== 200) {
            let message = yield resp.json();
            if (!message) {
                message = "error.unexpected";
            }
            yield put(documentError(message));
            return
        }
        const pj = yield resp.json();
        const newDocs = [...currentDocs, pj];
        yield put(submitDocumentSuccess());
        yield put(updateSingleUserField({field: "documents", value: newDocs}));
        newDocs.some(doc =>
            (doc.type === REGISTER || doc.type === KBIS) && (doc.status === "verifiee" || doc.status === "en attente")) ?
            yield put(stepSucceeded()) : yield put(documentError("error.doc.rejected"))
    } catch (e) {
        console.error({e});
        yield put(documentError(e));
    }

}

function* documentSagas() {
    yield takeLatest(submitDocumentRequest, submitDocumentSaga);
}

export default documentSagas;