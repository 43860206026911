import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {tabEnum} from "../../containers/ProfileEditContainer";
import NavButton from "../nav/NavButton";

const ProfileNav = (props) => {
    return (
        <>
            <ul className="flex flex-col space-y-1">
                <li>
                    <NavButton onClick={() => props.switchTab(tabEnum.account)} isActive={props.currentTab === tabEnum.account}>
                        <FormattedMessage id="label.mon.compte"/>
                    </NavButton>
                </li>
                <li>
                    <NavButton onClick={() => props.switchTab(tabEnum.info)} isActive={props.currentTab === tabEnum.info}>
                        <FormattedMessage id="label.addresses"/>
                    </NavButton>
                </li>
                <li>
                    <NavButton onClick={() => props.switchTab(tabEnum.cards)} isActive={props.currentTab === tabEnum.cards}>
                        <FormattedMessage id="common.credit.card"/>
                    </NavButton>
                </li>

                <li>
                    <NavButton onClick={() => props.switchTab(tabEnum.preference)} isActive={props.currentTab === tabEnum.preference}>
                        <FormattedMessage id="label.preference"/>
                    </NavButton>
                </li>
            </ul>
        </>
    )
};

ProfileNav.propTypes = {
    currentTab: PropTypes.string,
    signOut: PropTypes.func,
    switchTab: PropTypes.func
};


export default ProfileNav;