import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {tabEnum} from "../../containers/ProfileEditContainer";

const ProfileNavMobile = (props) => {
    return (
        <select
            onChange={(e) => props.switchTab(e.target.value)}
            className="bg-gray-200 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block w-full appearance-none text-xs mb-4">
            <FormattedMessage id='label.mon.compte'>
                {(message) => <option value={tabEnum.account}>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='label.addresses' >
                {(message) => <option value={tabEnum.info}>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='common.credit.card'>
                {(message) => <option value={tabEnum.cards}>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='label.preference'>
                {(message) => <option value={tabEnum.preference}>{message}</option>}
            </FormattedMessage>
        </select>
    )
};

ProfileNavMobile.propTypes = {
    signOut: PropTypes.func,
    switchTab: PropTypes.func
};


export default ProfileNavMobile;