import {PENDING, VALID} from "../../structs/apiConstants";
import {FormattedMessage} from "react-intl";
import Button from "@material-ui/core/Button";
import React from "react";


const InvalidId = (props) => {
    return (
        <div className="flex-auto">
            <div className="text-base bold">Identité</div>
            <ul className="list-disc ml-3">
            {props.IdCheckStatus !== VALID &&
            <li>
                {props.IdCheckStatus === PENDING ?
                    <span><FormattedMessage id="label.personal.id.pending"/></span> :
                    <button className="underline text-blue-500" onClick={props.setSumSubModal}>
                        <FormattedMessage id="label.confirm.personal.id"/>
                    </button>
                }
                <br/>
            </li>
            }
            {!props.hasValidProDocs &&
            <li>
                {props.hasValidAwaitingDocs ?
                    <span><FormattedMessage id="label.company.id.pending"/></span> :
                    <button className="underline text-blue-500" onClick={props.setDocModal}>
                        <FormattedMessage id="label.confirm.company.id"/>
                    </button>
                }
            </li>
            }
            </ul>
        </div>
    )
}

export default InvalidId