import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
    requestSumSubToken,
    updateUserApplicantIdRequest,
    selectSumSubToken,
    selectCurrentUserProfile,
    selectUserEmail,
} from "../reducers/authSlice";
import SumSub from "../components/SumSub";
import IdRegionSelect from "../components/profile/IdRegionSelect";
import {FormattedMessage} from "react-intl";

const SumSubContainer = props => {
    const dispatch = useDispatch();
    const [showSumsub, setShowSumsub] = useState(false);
    const [level, setLevel] = useState("basic-kyc-level");
    const token = useSelector(selectSumSubToken);
    const userProfile = useSelector(selectCurrentUserProfile);
    const email = useSelector(selectUserEmail);

    const selectLevel = (level) => {
        setLevel(level);
        setShowSumsub(true);
    };

    useEffect(() => {
        if (showSumsub) dispatch(requestSumSubToken({level, saleId: props.saleId}));
    }, [showSumsub]);

    return (
        <>
            {showSumsub && <div className="flex-1">
                <button onClick={() => setShowSumsub(false)} className="text-blue-500"><FormattedMessage id="label.change.region"/></button>
            </div>}
            {!showSumsub && <IdRegionSelect setLevel={(level) => selectLevel(level)}/>}
            {showSumsub && <div className="flex-grow">
                <SumSub
                    email={email}
                    userProfile={userProfile}
                    token={token}
                    requestSumSubToken={() => dispatch(requestSumSubToken({level, saleId: props.saleId}))}
                    updateUserApplicantIdRequest={(applicantId) => dispatch(updateUserApplicantIdRequest({applicantId}))}
                    isTunnel={props.isTunnel}
                    setCanValidateStep={props.setCanValidateStep}
                />
            </div>}
        </>
    )
};

SumSubContainer.propTypes = {
    isTunnel: PropTypes.bool,
    level: PropTypes.string,
    saleId: PropTypes.number,
    canValidateStep: PropTypes.func,
};

export default SumSubContainer;