import React from "react";
import MenuAccount from "./MenuAccount";
import config from "../../config";
import MenuSale from "./MenuSale";
import MenuCategory from "./MenuCategory";
import {useIntl} from "react-intl";

const Header = (props) => {
    const intl = useIntl()
    return (
         <div className="px-2 sm:px-0">
             <div className="w-full ml-auto mr-auto">
                 <div className="flex flex-wrap">
                     <div className="w-1/2">
                         <a href={`https://${config.domain}/${intl.locale}`}><div className={`logo-${config.site} w-4/5`} /></a>
                     </div>
                     <div className="w-1/2 text-right">
                         <MenuAccount signOut={props.signOut}/>
                     </div>
                 </div>
             </div>
             <div className="clear"/>
             <div className="w-full ml-auto mr-auto my-12 menu-desktop">
                 <div className="">
                     <div className="menu-sale xs:hidden w-72">
                         <MenuSale/>
                     </div>
                     <div className="menu-category xs:hidden w-auto text-right float-right">
                         <MenuCategory/>
                     </div>
                 </div>
             </div>
         </div>
    )
};

export default Header;
