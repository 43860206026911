import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    AmplifyState, selectAmplifyState,
    signOut,
} from "../reducers/authSlice";
import Footer from "../components/global/Footer";
import Header from "../components/global/Header";
import {checkPaymentStatus, paymentError, selectPaymentMessage} from "../reducers/paymentSlice";
import {DepositType, SecurityDepositType} from "../structs/apiConstants";
import {FormattedMessage} from "react-intl";

const WaitingPaymentContainer = (props) => {
    const dispatch = useDispatch();
    const amplifyState = useSelector(selectAmplifyState);
    const infoMessage = useSelector(selectPaymentMessage);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isTunnel = urlSearchParams.get("isTunnel") === "true";

    useEffect(() => {
        if (amplifyState === AmplifyState.SignedIn) {
            const payType = parseInt(urlSearchParams.get("type"));
            const payId = urlSearchParams.get("paymentId");
            const saleId = parseInt(urlSearchParams.get("saleId"));
            if (!payId || (!payType && payType !== 0)) {
                dispatch(paymentError("payment.status.error"));
                return
            }
            if ((payType === DepositType || payType === SecurityDepositType) && !saleId) {
                dispatch(paymentError("payment.status.error"));
                return
            }
            dispatch(checkPaymentStatus({payType: payType, payId: payId, saleId: saleId, isTunnel: isTunnel}));
        }
    }, [amplifyState]);
    return (
        <>
            <Header signOut={() => dispatch(signOut())}/>
                {isTunnel && infoMessage &&
                    <div className="text-2xl dark:text-gray-300 font-semibold text-center pt-20">
                        <FormattedMessage id={infoMessage}/>
                    </div>
                }
            <Footer/>
        </>
    )
};

WaitingPaymentContainer.propTypes = {
};

export default WaitingPaymentContainer;