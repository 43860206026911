import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import DocumentSubmitContainer from "../../containers/DocumentSubmitContainer";
import ConfirmPhoneContainer from "../../containers/ConfirmPhoneContainer";
import SumSubContainer from "../../containers/SumSubContainer";
import IngenicoContainer from "../../containers/IngenicoContainer";
import {DepositType, RESTRICTED_COVERAGE} from "../../structs/apiConstants";
import Button from "@material-ui/core/Button";
import SelectCard from "../tools/SelectCard";
import CenteredModal from "../tools/CenteredModal";
import ValidRegistration from "./ValidRegistration";
import InvalidRegistration from "./InvalidRegistration";
import {
    hasAwaitingDocs,
    hasNonRejectedDocs,
    hasValidDocs,
} from "../../Passeport";
import {registrationType, saleRestrictionsType, saleType} from "../../structs/types";
import {FormattedMessage} from "react-intl";

const DOC = 0;
const PHONE = 1;
const SUMSUB = 2;
const PAYMENT = 3;

const Registration = (props) => {
    const {registration, restrictions} = props;

    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [amount, setAmount] = useState(0);
    const [paymentType, setPaymentType] = useState(0);
    const [selectedCard, setSelectedCard] = useState( "" );

    const setModal = (modalType, paymentType, amount) => {
        if (modalType === PAYMENT) {
            setPaymentType(paymentType);
            setAmount(amount);
        }
        setModalType(modalType);
        setOpenModal(true);
    };

    useEffect(() => {
        props.refreshUserProfile();
    }, [])

    useEffect(() => {
        if (props.userProfile.creditCards.length > 0 && selectedCard !== props.userProfile.creditCards[0].alias) {
            setSelectedCard(props.userProfile.creditCards[0].alias);
        }
    }, [props.userProfile]);

    const cardSelector = props.userProfile.creditCards.length > 0 &&
        <>
            <span className="text-sm my-2"><FormattedMessage id="label.select.card" /></span>
            <br/>
            <div>
                <SelectCard addOption creditCards={props.userProfile.creditCards} onChange={(e) => setSelectedCard(e.target.value)} defaultValue={selectedCard}/>
            </div>
        </>;

    return (
        <>
            <CenteredModal
                openModal={openModal}
                onCloseModal={() => {
                    props.refreshRegistration();
                    props.refreshUserProfile();
                    setOpenModal(false);
                }}
            >
                {/*TODO: meilleur intégration front (centrage, taille restreinte) + meilleur gestion du close*/}
                    {modalType === DOC && <DocumentSubmitContainer saleId={props.saleId}/>}
                    {modalType === PHONE && <ConfirmPhoneContainer updateForm={props.updateForm}/>}
                    {modalType === SUMSUB && <SumSubContainer saleId={props.saleId}/>}
                    {modalType === PAYMENT && <IngenicoContainer type={paymentType} amount={amount} selectedCard={selectedCard} saleId={props.saleId}/>}
            </CenteredModal>
            <div className="flex flex-col justify-between h-full mx-5">
                <div className="flex-grow">
                    {registration.status === 2 ?
                        <ValidRegistration
                            hasRemainingLimit={registration.hasRemainingLimit}
                            hasIngenico={restrictions.hasIngenico}
                            biddingLimit={registration.maxAmount}
                            remainingLimit={registration.remainingLimit}
                            setPaymentModal={(amount) => setModal(PAYMENT, DepositType, amount)}
                            coverage={registration.filtered === "RESTRICTED" ? RESTRICTED_COVERAGE : restrictions.biddingLimit.coverage}
                            cardSelector={cardSelector}
                            saleCurrency={props.saleInfo.currency}
                            cp={{
                                name: props.saleInfo.cpName,
                                phone: props.saleInfo.cpPhone,
                                mail: props.saleInfo.cpMail,
                                address: props.saleInfo.cpAddress,
                            }}
                            depositStartDate={new Date(restrictions.depositStartDate*1000)}
                        /> :
                        <InvalidRegistration
                            {...registration.statusProperties}
                            isFiltered={registration.filtered !== "UNFILTERED"}
                            registrationStatus={registration.status}
                            userIdCheckStatus={props.userProfile.idCheckStatus}
                            hasValidProDocs={hasValidDocs(props.userProfile)}
                            hasValidAwaitingDocs={hasAwaitingDocs(props.userProfile)}
                            hasNonRejectedProDocs={hasNonRejectedDocs(props.userProfile)}
                            deposit={restrictions.deposit}
                            depositStartDate={new Date(restrictions.depositStartDate*1000)}
                            setPaymentModal={(type, amount) => setModal(PAYMENT, type, amount)}
                            setPhoneModal={() => setModal(PHONE)}
                            setSumSubModal={() => setModal(SUMSUB)}
                            setDocModal={() => setModal(DOC)}
                            cardSelector={cardSelector}
                            cp={{
                                name: props.saleInfo.cpName,
                                phone: props.saleInfo.cpPhone,
                                mail: props.saleInfo.cpMail,
                                address: props.saleInfo.cpAddress,
                            }}
                        />
                    }
                </div>
                <div className="self-end w-1/3 md:w-1/5 mb-4 lg:mb-0">
                    <Button className="w-full" size="large" color="primary" variant="contained" onClick={props.return}>retour</Button>
                </div>
            </div>
        </>
    )
};

Registration.propTypes = {
    refreshUserProfile: PropTypes.func.isRequired,
    refreshRegistration: PropTypes.func.isRequired,
    return: PropTypes.func.isRequired,
    registration: registrationType,
    restrictions: saleRestrictionsType,
    saleId: PropTypes.number,
    saleInfo: saleType,
    userProfile: PropTypes.object,
    updateForm: PropTypes.func,
};

export default Registration;