import {createSlice} from "@reduxjs/toolkit";
import {AuthState} from '@aws-amplify/ui-components';
import Passeport from "../Passeport";

const initialState = {
    loading: false,
    amplifyState: AuthState.SignedOut,
    formInputs: {},
    isFederatedUser: false,
    success: "",
    user: null,
    error: null,
    currentUserProfile: Passeport({}),
    sumSubToken: "",
    fingerprint: null,
    forgotSent: false,
};

export const AmplifyState = Object.assign({
    ProfileEdit: "ProfileEdit",
}, AuthState);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        restoreDefault: state => Object.assign(state, initialState),
        updateFormInputs: (state, action) => {
            state.formInputs[action.payload.name] = action.payload.value;
        },
        resetFormInputs: state => {
            state.formInputs = {};
        },
        signInRequest: (state, action) => {
            state.loading = true;
            state.success = "";
            state.error = null;
        },
        signInWithAppleRequest: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.user;
            if (state.user && state.user.method === "apple") {
                state.isFederatedUser = true;
            }
            state.amplifyState = AmplifyState.SignedIn;
        },
        refreshUserProfile: state => {
            state.loading = true;
            state.error = null;
        },
        updateUserProfile: (state, action) => {
            state.loading = false;
            state.error = null;
            state.currentUserProfile = Passeport(action.payload);
        },
        updateSingleUserField: (state, action) => {
            state.currentUserProfile[action.payload.field] = action.payload.value;
        },
        forgotPasswordRequest: state => {
            state.success = "";
            state.error = null;
        },
        confirmForgotPasswordRequest: state => {
            state.loading = true;
            state.error = "";
        },
        confirmForgotPasswordSuccess: state => {
            state.loading = false;
            state.success = "success.confirm.pwd.changed";
        },
        changePasswordRequest: state => {
            state.loading = true;
            state.success = "";
            state.error = null;
        },
        changePasswordSuccess: state => {
            state.loading = false;
            state.formInputs.password = '';
            state.formInputs.newPassword = '';
            state.success = "success.confirm.pwd.changed";
        },
        signUpRequest: state => {
            state.loading = true;
            state.success = "";
            state.error = null;
        },
        signUpSuccess: (state, action) => {
            state.loading = false;
            state.amplifyState = AmplifyState.SignUp
        },
        confirmSignUpRequest: state => {
            state.loading = true;
            state.success = "";
            state.error = null;
        },
        resendConfirmationMail: _ => {},
        resendConfirmationMailSuccess: state => {
            state.success = "success.confirm.mail.sent";
        },
        requestConfirmationCodeSMS: state => {
            state.success = "";
        },
        requestConfirmationCodeSMSSuccess: state => {
            state.success = "success.confirm.sms.sent";
        },
        confirmPhoneRequest: state => {
            state.success = "";
            state.loading = true;
            state.error = null;
        },
        confirmPhoneSuccess: state => {
            state.loading = false;
            state.error = null;
            state.success = "success.confirm.phone";
            state.currentUserProfile.phoneConfirmed = true;
        },
        confirmSignUpSuccess: state => {
            state.loading = false;
            state.success = "success.sign.up";
            state.amplifyState = AmplifyState.SignedOut;
        },
        saveProfileRequest: state => {
            state.loading = true;
            state.success = "";
            state.error = null;
        },
        saveProfileSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.success = "success.profile.update";
            state.formInputs = {};
        },
        signOut: state => {
            state.loading = true;
            state.amplifyState = AmplifyState.SignedOut;
        },
        requestSumSubToken: (state, action) => {
            state.loading = true;
            state.error = null;
            state.saleId = action.payload.saleId;
        },
        requestSumSubTokenSuccess: (state, action) => {
            state.loading = false;
            state.sumSubToken = action.payload;
        },
        updateUserApplicantIdRequest: _ => {},
        continueAsClick: _ => {},
        resetMessage: state => {
            state.error = null;
            state.success = "";
        },
        setLoading: state => {
            state.loading = true;
        },
        authError: (state, action) => {
            state.loading=false;
            state.error = action.payload;
        },
        updateFingerprintRequest: state => {
            state.loading = true;
        },
        updateFingerprintSuccess: (state, action) => {
            state.loading = false;
            state.fingerprint = action.payload;
        }
    },
});

export const selectAmplifyState = state => state.auth.amplifyState;
export const selectUser = state => state.auth.user;
export const selectIsFederatedUser = state => state.auth.isFederatedUser;
export const selectCurrentUserProfile = state => state.auth.currentUserProfile;
export const selectUserSelectedCard = (state, selectedCard) => state.auth.currentUserProfile.creditCards.find(card => card.alias === selectedCard);
export const selectCurrentUserProfileField = (state, field) => state.auth.currentUserProfile[field];
export const selectError = state => state.auth.error;
export const selectLoading = state => state.auth.loading;
export const selectSuccess = state => state.auth.success;
export const selectFormInputs = state => state.auth.formInputs;
export const selectSumSubToken = state => state.auth.sumSubToken;
export const selectUserEmail = state => state.auth.user.email;
export const selectFingerprint = state => state.auth.fingerprint;

export const {
    restoreDefault,
    updateFormInputs,
    resetFormInputs,
    signInRequest,
    signInSuccess,
    refreshUserProfile,
    updateUserProfile,
    signUpRequest,
    signInWithAppleRequest,
    signUpSuccess,
    confirmSignUpRequest,
    resendConfirmationMail,
    resendConfirmationMailSuccess,
    requestConfirmationCodeSMS,
    updateSingleUserField,
    requestConfirmationCodeSMSSuccess,
    confirmPhoneRequest,
    confirmPhoneSuccess,
    confirmSignUpSuccess,
    saveProfileRequest,
    saveProfileSuccess,
    changePasswordRequest,
    changePasswordSuccess,
    forgotPasswordRequest,
    confirmForgotPasswordRequest,
    confirmForgotPasswordSuccess,
    signOut,
    requestSumSubToken,
    requestSumSubTokenSuccess,
    updateUserApplicantIdRequest,
    continueAsClick,
    resetMessage,
    setLoading,
    authError,
    updateFingerprintRequest,
    updateFingerprintSuccess,
} = authSlice.actions;

export default authSlice.reducer;