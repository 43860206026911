import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, useIntl} from "react-intl";
import config from "../config";

const Cgu = (props) => {
    const intl = useIntl()
    return (
        <div className="text-sm">
            {props.showCGU && <label>
                <input id="cgu" key="cgu" name="cgu" type="checkbox" className="mr-2" onChange={props.onChange} />
                <FormattedMessage id="label.connaissance.accepte"/> <a href={`https://${config.domain}/${intl.locale}/general-terms`} className="underline"><FormattedMessage id={`label.condition.utilisation.${config.site}`}/></a>.*
            </label>}
            <br/>
            <div className="w-0 my-3 sm:my-0"/>
            <label className="mt-2">
                <input id="drouotNews" key={"drouotNews-" + props.drouotNewsChecked} name="newsletterDsi" type="checkbox" className="mr-2" onChange={props.onChange} defaultChecked={props.drouotNewsChecked}/>
                <FormattedMessage id={`label.info.${config.site}`}/>
            </label>
            <br/>
            <label className="mt-2">
                <input id="partnerNews" key={"partnerNews-" + props.partnerNewsChecked} name="newsletterPartner" type="checkbox" className="mr-2" onChange={props.onChange} defaultChecked={props.partnerNewsChecked} />
                <FormattedMessage id="label.info.partenaire"/>
            </label>
        </div>
    )
};

Cgu.propTypes = {
    showCGU: PropTypes.bool,
    drouotNewsChecked: PropTypes.bool,
    partnerNewsChecked: PropTypes.bool,
};

export default Cgu;