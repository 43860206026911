import Modal from "@material-ui/core/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

const CenteredModal = (props) => {
    return (
        <Modal
            open={props.openModal}
            onClose={props.onCloseModal}
            aria-labelledby="centered-modal-title"
            aria-describedby="centered-modal-description"
            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        >
            <div className="flex flex-col justify-between bg-white p-5 max-w-screen-lg" style={{maxHeight: "95vh"}}>
                <div className="flex flex-row-reverse">
                    <Button onClick={props.onCloseModal}><span className="mr-2"><FormattedMessage id="label.close"/> <FontAwesomeIcon icon={faTimes} size="lg"/></span></Button>
                </div>
                <div className="w-full flex-grow flex flex-col justify-start items-center px-2 md:px-5 overflow-y-auto">
                    {props.children}
                </div>
            </div>
        </Modal>
    )
};

CenteredModal.propTypes = {
    onCloseModal: PropTypes.func,
    openModal: PropTypes.bool,
};

export default CenteredModal