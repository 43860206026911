import { createSlice } from "@reduxjs/toolkit";

export const defaultLocation = '/';

const initialState = {
    redirUri: "",
    initialLocation: defaultLocation,
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        resetUserPath: state => Object.assign(state, initialState),
        setUserPath: (state, action) => {
            if (action.payload.redirUri) state.redirUri = action.payload.redirUri;
            if (action.payload.initialLocation) state.initialLocation = action.payload.initialLocation;
        },
    }
});

export const selectRedirUri = state => state.navigation.redirUri;
export const selectInitialLocation = state => state.navigation.initialLocation;

export const {
    resetUserPath,
    setUserPath,
}  = navigationSlice.actions;

export default navigationSlice.reducer