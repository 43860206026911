import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './asset/css/custom.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from "./store";
import App from './App';
import { IntlProvider } from 'react-intl';
import Message from "./translations";
import config from "./config";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

const selectLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    let locale = params.get("lang");
    if (!locale) {
        const cookies = document.cookie.split(";")
        const index = cookies.findIndex(val => val.includes("locale"))
        if (index >= 0) {
            locale = cookies[index].split("=").pop()
        }
    }
    if (locale && locale.match(new RegExp(`it|en|fr|zh|de|es`))) {
        return locale
    }
    return "fr"
}

ReactDOM.render(
  <React.StrictMode>
      <div className="relative w-full h-full min-h-screen">
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistStore(store)}>
                  <IntlProvider locale={selectLanguage()} defaultLocale="en" messages={Message[selectLanguage()]}>
                      <ConnectedRouter history={history}>
                          <App />
                      </ConnectedRouter>
                  </IntlProvider>
              </PersistGate>
          </Provider>
      </div>
  </React.StrictMode>,
  document.getElementById('root')
);
