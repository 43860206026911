import {utcToZonedTime} from 'date-fns-tz'
import {FormattedMessage} from "react-intl";
import React from "react";

const SaleDate = props => {
    const saleDate = utcToZonedTime(new Date(props.date*1000), props.date.timeZone);
    return (
        <span>{saleDate.getDate()} <FormattedMessage id={`month.${saleDate.getMonth()}`} /> {saleDate.getFullYear()} - {saleDate.getHours() < 10 ? `0${saleDate.getHours()}` : saleDate.getHours()}:{saleDate.getMinutes() < 10 ? `0${saleDate.getMinutes()}` : saleDate.getMinutes()}</span>
    )
};

export default SaleDate;