import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {INVALID, NewCardType, SecurityDepositType} from "../../structs/apiConstants";
import {FormattedMessage} from "react-intl";
import IconTick from "../tools/IconTick";
import InvalidRegTitle from "./InvalidRegTitle";
import InvalidId from "./InvalidId";
import CpContactInfo from "../CpContactInfo";

const InvalidRegistration = (props) => {
    console.log(props);
    return (

        <div className="mt-4 mb-4 h-full">
            <InvalidRegTitle
                registrationStatus={props.registrationStatus}
                isFiltered={props.isFiltered}
                invalidConditions={
                    !props.validPaymentCard || !props.validPhone || !props.validSecurityDeposit
                    || props.blacklisted || (!props.validIdDocument && (props.userIdCheckStatus === INVALID || !props.hasNonRejectedProDocs))}
            />

            <div className="flex flex-col h-4/6 justify-between my-8">
            {props.isFiltered && props.registrationStatus !== 0 && <CpContactInfo cp={props.cp} additionalTitleId="label.registration.filtered.contact"/>}

            {!props.validSecurityDeposit &&
            <div className="flex-auto">
                {props.depositStartDate && Date.now() < props.depositStartDate.getTime() ?
                    <span><FormattedMessage id="label.caution.start"/> {props.depositStartDate.getDate()} <FormattedMessage id={`month.${props.depositStartDate.getMonth()}`}/> {props.depositStartDate.getFullYear()}</span> :
                    <>
                        <div className="text-base bold"><FormattedMessage id="label.deposit"/></div>
                        <div className="mt-2"> <FormattedMessage id="label.deposit.explain" values={{amount: props.deposit}}/></div>
                        {props.cardSelector}
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => props.setPaymentModal(SecurityDepositType, props.deposit)}>
                            <FormattedMessage id="label.pay.deposit"/>
                        </Button>
                    </>
                }
            </div>}

            {!props.validPhone && <div className="flex-auto">
                <div className="text-base dark:text-gray-300 font-semibold"><FormattedMessage id="label.should.confirm.phone"/> <IconTick/></div>
                <Button variant="contained" color="primary" onClick={props.setPhoneModal}>
                    <FormattedMessage id="label.confirm"/>
                </Button>
            </div>
            }

            {!props.validIdDocument && <InvalidId
                IdCheckStatus={props.userIdCheckStatus}
                hasValidProDocs={props.hasValidProDocs}
                hasValidAwaitingDocs={props.hasValidAwaitingDocs}
                setSumSubModal={props.setSumSubModal}
                setDocModal={props.setDocModal}
            />}

            {!props.validPaymentCard && props.deposit === 0 &&
                <div className="flex-auto">
                    <div className="text-base dark:text-gray-300 font-semibold"><FormattedMessage id="common.credit.cards"/></div>
                    <span className="text-sm"><FormattedMessage id="label.should.confirm.credit.card.explain"/></span>
                    <br/>
                    <button className="underline text-blue-500 text-sm" onClick={() => props.setPaymentModal(NewCardType, 0)}>
                        <FormattedMessage id="label.should.confirm.credit.card"/>
                    </button>
                    </div>
            }
            </div>
        </div>
    )

};


InvalidRegistration.propTypes = {
    isFiltered: PropTypes.bool,
    validIdDocument: PropTypes.bool,
    registrationStatus: PropTypes.number,
    userIdCheckStatus: PropTypes.string,
    hasValidProDocs: PropTypes.bool,
    hasNonRejectedProDocs: PropTypes.bool,
    hasValidAwaitingDocs: PropTypes.bool,
    validSecurityDeposit: PropTypes.bool,
    validPaymentCard: PropTypes.bool,
    validPhone: PropTypes.bool,
    deposit: PropTypes.number,
    depositStartDate: PropTypes.instanceOf(Date),
    setPaymentModal: PropTypes.func,
    setDocModal: PropTypes.func,
    setPhoneModal: PropTypes.func,
    setSumSubModal: PropTypes.func,
    cardSelector: PropTypes.element,
};


export default InvalidRegistration