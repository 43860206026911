import React, {useState} from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {FormattedMessage, useIntl} from "react-intl";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de"
import esLocale from "date-fns/locale/es"
import itLocale from "date-fns/locale/it"
import cnLocale from "date-fns/locale/zh-CN"
import DateFnsUtils from '@date-io/date-fns';
import config from "../../config";

const localeMap = {
    en: enLocale,
    fr: frLocale,
    cn: cnLocale,
    es: esLocale,
    it: itLocale,
    de: deLocale,
};

export default function DatePickers(props) {
    const intl = useIntl();
    const [selectedDate, handleDateChange] = useState(null);


    return (
        <FormattedMessage id="profile.birthdate.format" >
            {msg => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[intl.locale]}>
                    <KeyboardDatePicker
                        className='pl-1 spe-coo'
                        value={selectedDate}
                        format="dd/MM/yyyy"
                        invalidDateMessage={<span className='text-red-500'><FormattedMessage id={"error.invalid.date"}/></span>}
                        emptyLabel={msg}
                        label={<FormattedMessage id="profile.birthdate" />}
                        cancelLabel={<FormattedMessage id={"label.cancel"}/>}
                        disableFuture={props.disableFuture}
                        openTo="year"
                        onChange={(value) => {
                            handleDateChange(value);
                            if (value instanceof Date && !isNaN(value)) {
                                // setting utc hours to offset to avoid bug with some timezone that changes day when switching in utc
                                value.setUTCHours(value.getTimezoneOffset()/60)
                                //date to json is provided from local to utc, so it can cause bug because of time offset
                                value.setUTCDate(value.getDate());
                                value.setHours(0);
                                const e = {target: {}};
                                e.target.value = value.toJSON();
                                e.target.name = props.name;
                                props.onChange(e);
                            }
                        }} />
                </MuiPickersUtilsProvider>)}
        </FormattedMessage>
    );
}