import React from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import AlertBar from "./AlertBar";
import {FormattedMessage} from "react-intl";

const Error = (props) => {
    return (
        <AlertBar open={props.errorMessage} closeAction={props.resetMessage}>
            <div className="error text-white text-sm bg-red-700 bg-opacity-80 w-4/6 flex flex-row justify-between">
                <div className="flex-grow">
                    <span><FormattedMessage id={props.errorMessage}/></span>
                </div>
                <div className="flex-1 text-right">
                    <Button onClick={props.resetMessage}><span className="mr-1"><FontAwesomeIcon color="white" icon={faTimes} size="lg"/> </span></Button>
                </div>
            </div>
        </AlertBar>
    )
};

Error.propTypes = {
    resetMessage: PropTypes.func,
    errorMessage: PropTypes.string,
};

export default Error;