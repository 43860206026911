import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPaymentTokenization
} from "../reducers/paymentSlice";
import {selectRestrictionsForSale} from "../reducers/registrationSlice";
import {NewCardType} from "../structs/apiConstants";
import config from "../config"
import {selectUserSelectedCard} from "../reducers/authSlice";
import IngenicoIframeForm from "../components/payment/IngenicoIframeForm";

// if this container is updated it will re-render the iframe which will cause cross security issues
const dontUpdateComponent = (prevProps, nextProps) => true;

const IngenicoContainer = React.memo((props) => {
    const saleRestrictions = useSelector(state => selectRestrictionsForSale(state, props.saleId));
    const pspid = props.type === NewCardType ? config.drouotPspid : saleRestrictions.ingenicoPspid;
    const selectedCard = useSelector((state) => selectUserSelectedCard(state, props.selectedCard))
    const tokenization = useSelector(selectPaymentTokenization);

    return (
        <
            IngenicoIframeForm
            pspid={pspid}
            selectedCard={selectedCard}
            amount={props.amount}
            type={props.type}
            saleId={props.saleId}
            isTunnel={props.isTunnel}
            tokenization={tokenization}

        />
    )
}, dontUpdateComponent);
IngenicoContainer.propTypes = {
    type: PropTypes.number.isRequired,
    amount: PropTypes.number,
    saleId: PropTypes.number,
    selectedCard: PropTypes.string,
    isTunnel: PropTypes.bool,
};

export default IngenicoContainer;