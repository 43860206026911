import {useEffect} from 'react';
import PropTypes from "prop-types";

import snsWebSdk from '@sumsub/websdk'
import config from "../config";
import {useIntl} from "react-intl";

const SumSub = (props) => {

    const intl = useIntl()
    useEffect(() => {
        const snsWebSdkInit = snsWebSdk.init(props.token, () => {
            // EXPIRATION HANDLER
            /* generate a new token and launch WebSDK again */
            props.requestSumSubToken()
        });

        const snsWebSdkInstance = snsWebSdkInit.withConf({
            lang: intl.locale,
            email: props.email,
            phone: `+${props.userProfile.phone}`,
            firstName: props.userProfile.firstName,
            lastName: props.userProfile.lastName,
        })
            .on("idCheck.onApplicantLoaded", (payload) => {
                props.updateUserApplicantIdRequest(payload.applicantId)
            })
            .on("idCheck.onApplicantSubmitted", (payload) => {
                if (props.isTunnel) props.setCanValidateStep(true);
            })
            .on("idCheck.onApplicantResubmitted", (payload) => {
                if (props.isTunnel) props.setCanValidateStep(true);
            })
            .on("idCheck.onApplicantStatusChanged", (payload) => {
                if (payload.reviewResult && payload.reviewResult.reviewAnswer && payload.reviewResult.reviewAnswer === "GREEN") {
                    if (props.isTunnel) props.setCanValidateStep(true);
                }
            })
            .on("idCheck.onError", (error) => {
                console.error("sdk onError", error);
            })
            .build();

        snsWebSdkInstance.launch('#sumsub-websdk-container');
    }, [props.token]);


    return (
        <div id="sumsub-websdk-container" className="w-full"></div>
)};

SumSub.propTypes = {
    email: PropTypes.string,
    userProfile: PropTypes.object,
    token: PropTypes.string,
    isTunnel: PropTypes.bool,
    requestSumSubToken: PropTypes.func,
    updateUserApplicantIdRequest: PropTypes.func,
};

export default SumSub;