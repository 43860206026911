import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepTitle from "../components/nav/StepTitle";
import StepWindowLayout from "../components/nav/StepWindowLayout";
import {updateFormInputs} from "../reducers/authSlice";
import {
    nextActiveStep,
    selectActiveStep,
    selectCanValidateStep,
    selectSteps, selectStepSuccess,
    setActiveStep,
    setCanValidateStep,
    stepSucceeded,
    resetTunnel,
} from "../reducers/tunnelSlice";
import { FormattedMessage } from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import HeaderLight from '../components/global/HeaderLight'
import GAScripts from "../components/GAScripts";
import GTMScripts from "../components/GTMScripts";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}), {index: 1});

const NavStepHorContainer = (props) => {
    const classes = useStyles();
    const activeStep = useSelector(selectActiveStep);
    const canValidateStep = useSelector(selectCanValidateStep);
    const steps = useSelector(selectSteps);
    const success = useSelector(selectStepSuccess);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCanValidateStep(false));
    }, [activeStep]);

    useEffect(() => {
        if (success) {
            if (isLastStep()) {
                dispatch(props.endAction());
            } else {
                dispatch(nextActiveStep());
            }
        }
    }, [success]);

    const stepsComponent = steps.map(step => (
        <StepTitle titleId={step.stepTitle}/>
    ));

    const totalSteps = () => {
        return stepsComponent.length;
    };

    const isStepOptional = (step) => {
        return steps[step].isOptional;
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }
        if (!isLastStep()) dispatch(setActiveStep(activeStep + 1));
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const getStepContent = (index) => {
        const step = steps[index];
        // cloning component to add props on the go
        return (
            <StepWindowLayout titleId={step.windowTitle} subtitleId={step.subtitleId} textId={step.TextId}>
                {React.cloneElement(step.component, {
                    canValidateStep: canValidateStep,
                    setCanValidateStep: (canValidate) => dispatch(setCanValidateStep(canValidate)),
                    updateForm: props.updateForm,
                    rawUpdateInput: (name, value) => dispatch(updateFormInputs({name, value})),
                })}
            </StepWindowLayout>
        )
    };

    const handleNext = (...payload) => {
        const validator = steps[activeStep].validatorAction;
        if (validator) {
            dispatch(steps[activeStep].validatorAction({...payload, isLastStep: false, endAction: null}))
        } else {
            dispatch(stepSucceeded())
        }
    };

    const cancel = () => {
        dispatch(resetTunnel());
        dispatch(push('/'));
    };

    return (
        <>
            <GAScripts/>
            <GTMScripts/>
            <HeaderLight/>
            <div className={classes.root}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                    {stepsComponent.map((label, index) => {
                        const stepProps = {};
                        const buttonProps = {};
                        if (isStepOptional(index)) {
                            buttonProps.optional = <Typography variant="caption"><FormattedMessage id="label.optional"/></Typography>;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton
                                    completed={index < activeStep}
                                    {...buttonProps}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === stepsComponent.filter(step => !step.isOptional).length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                <FormattedMessage id="label.steps.completed"/>
                            </Typography>
                        </div>
                    ) : (

                        <div className="justify-center">
                            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div className="flex justify-center">
                                <Button onClick={cancel} className={classes.button}>
                                    <FormattedMessage id="label.cancel"/>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!canValidateStep}
                                >
                                    {steps[activeStep].validatorButtonId ?
                                        <FormattedMessage id={steps[activeStep].validatorButtonId}/>: <FormattedMessage id="common.next"/>}
                                </Button>
                                {isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        <FormattedMessage id="label.skip"/>
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

NavStepHorContainer.propTypes = {
    updateForm: PropTypes.func.isRequired,
};

export default NavStepHorContainer;
