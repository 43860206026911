import {FormattedMessage} from "react-intl";
import React from "react";
import {useDispatch} from "react-redux";
import {continueAsClick} from "../reducers/authSlice";
import Button from "@material-ui/core/Button";

const CongratulationContainer = (props) => {
    const dispatch = useDispatch();
    return (
        <>
            <h3><FormattedMessage id="label.congratulation"/></h3>
            <p>
                <FormattedMessage id="label.compte.ok"/><br/>
                <FormattedMessage id="label.participer.completer"/>
            </p>
            <Button onClick={() => dispatch(continueAsClick())} variant="contained" color="primary">
                <FormattedMessage id="common.finish"/>
            </Button>
        </>
    )
};

export default CongratulationContainer;