import {Helmet} from "react-helmet";
import config from "../config";

const GTMScripts = () => {
    if (config.isRecette) {
        return null;
    }
    const gtmTagId = config.isMoniteur ? config.gtmTagIdMoniteur : config.gtmTagIdDrouot;
    if (!gtmTagId) {
        return null;
    }
    return (
        <>
            <Helmet>
                <script>
                    {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gtmTagId}');
                    `}

                </script>
            </Helmet>
        </>
    )
}
export default GTMScripts;