import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    confirmPhoneRequest,
    requestConfirmationCodeSMS,
    saveProfileRequest,
    selectCurrentUserProfile,
} from "../reducers/authSlice";
import ConfirmPhone from "../components/ConfirmPhone";

const ConfirmPhoneContainer = (props) => {
    const dispatch = useDispatch();
    const currentUserProfile = useSelector(selectCurrentUserProfile);
    return (
        <ConfirmPhone
            phone={currentUserProfile.phone}
            savePhone={() => dispatch(saveProfileRequest())}
            requestConfirmationCodeSMS={() => dispatch(requestConfirmationCodeSMS())}
            confirmPhoneRequest={() => dispatch(confirmPhoneRequest())}
            setCanValidateStep={props.setCanValidateStep}
            isTunnel={props.isTunnel}
            updateForm={props.updateForm}
        />
    )
};

ConfirmPhoneContainer.propTypes = {
    updateForm: PropTypes.func,
    isTunnel: PropTypes.bool
};


export default ConfirmPhoneContainer