import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import {TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import SelectCountry from "../tools/SelectCountry";
import ConfirmPhoneContainer from "../../containers/ConfirmPhoneContainer";
import IconTick from "../tools/IconTick";
import CenteredModal from "../tools/CenteredModal";
import PhoneField from "../tools/PhoneField";
import ProfileField from "./ProfileField";
import ProfileAddress from "./ProfileAddress";
import {selectFormInputs} from "../../reducers/authSlice";


const ProfileAddressDeliv = (props) => {
    const [newCountry, setNewCountry] = useState("");
    const [isSameAddress, setIsSameAddress] = useState(false)

    useEffect(() => {
        setNewCountry(props.currentUserProfile.countryDeliv)
    }, [props.currentUserProfile.countryDeliv]);

    useEffect(() => {
        if(props.currentUserProfile.address ){
            checkIfAddressDelivIsSameAsAddress()
        }
    }, [props.currentUserProfile.address, props.currentUserProfile.addressDeliv]);

    useEffect(() => {
        setIsSameAddress(false)
        props.onChange({target: {name: "isAddressDelivSameAsAddress", type:"checkbox",checked:false}})

    }, [props.formInputsAddress, props.formInputsAdditionalAddress, props.formInputsZipCode, props.formInputsCity, props.formInputsCountry, props.formInputsState]);

    const onChangeCheckbox = (e) => {
        props.onChange(e)
        setIsSameAddress(e.target.checked)
    }

    const checkIfAddressDelivIsSameAsAddress = () => {
        if(props.currentUserProfile.address === props.currentUserProfile.addressDeliv &&
            props.currentUserProfile.additionalAddress === props.currentUserProfile.additionalAddressDeliv &&
            props.currentUserProfile.zipCode === props.currentUserProfile.zipCodeDeliv &&
            props.currentUserProfile.city === props.currentUserProfile.cityDeliv &&
            props.currentUserProfile.country === props.currentUserProfile.countryDeliv &&
            props.currentUserProfile.state === props.currentUserProfile.stateDeliv){
           setIsSameAddress(true)
            return true;
        }

        setIsSameAddress(false)
        return false;
    }

return (
        <>

            <h2 className="mt-2 text-xl dark:text-gray-300 font-extrabold capitalize">
                <FormattedMessage id="label.adresse.deliv"/>
            </h2>

            <label className="mt-2 ">
                <input id="isSameAsMainAddress" key={`isSameAsMainAddress`} name="isAddressDelivSameAsAddress" type="checkbox" className="mr-2"
                       onChange={onChangeCheckbox} checked={isSameAddress}/>
                <FormattedMessage id="label.same.address"/>
            </label>
            <br/> <br/>

            {!isSameAddress && <>
                <ProfileField
                    key={`addressDeliv.${props.currentUserProfile.addressDeliv}`}
                    label="label.adresse.deliv"
                    inputLabel="label.adresse.deliv"
                    name="addressDeliv" value={props.currentUserProfile.addressDeliv} onChange={props.onChange}/>


                <ProfileField key={`additionalAddressDeliv.${props.currentUserProfile.additionalAddressDeliv}`}
                              inputLabel="label.addresscomplement" name="additionalAddressDeliv"
                              value={props.currentUserProfile.additionalAddressDeliv} onChange={props.onChange}
                              optional={true}/>


                <ProfileField key={`zipCodeDeliv.${props.currentUserProfile.zipCodeDeliv}`} inputLabel="label.zipcode"
                              name="zipCodeDeliv" value={props.currentUserProfile.zipCodeDeliv} onChange={props.onChange}/>


                <ProfileField key={`cityDeliv.${props.currentUserProfile.cityDeliv}`} inputLabel="label.city" name="cityDeliv"
                              value={props.currentUserProfile.cityDeliv} onChange={props.onChange}/>




                <div className="inline-block mt-2 w-0 sm:w-2/6 align-top"/>
                <div className="inline-block mt-2 w-5/6 sm:w-3/6">
                        <SelectCountry onChange={e => {
                            setNewCountry(e.target.value);
                            props.onChange(e);
                        }} defaultValue={props.currentUserProfile.countryDeliv} isCountryDeliv/>

                </div>
                <div className="inline-block pl-1 w-1/6 text-center align-bottom h-8"><IconTick
                    isValid={props.currentUserProfile.countryDeliv !== ""}/></div>

            {newCountry !== '75' &&
                <ProfileField key={`state.${props.currentUserProfile.stateDeliv}`} inputLabel="label.addressstate" name="state" value={props.currentUserProfile.stateDeliv} onChange={props.onChange} optional={true} />
            }
            </> }

            <div className="clear"/>

        </>
    )
};

ProfileAddressDeliv.propTypes = {
    formInputsAddress: PropTypes.string,
    formInputsAdditionalAddress: PropTypes.string,
    formInputsZipCode: PropTypes.string,
    formInputsCountry: PropTypes.string,
    formInputsCity: PropTypes.string,
    formInputsState: PropTypes.string,
    onChange: PropTypes.func,
    currentUserProfile: PropTypes.object,
};

export default ProfileAddressDeliv;