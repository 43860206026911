import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {
    getRegistrationForSaleRequest,
    registrationError,
    selectInfoForSale,
    selectRegistrationForSale,
    selectRestrictionsForSale,
} from "../reducers/registrationSlice";
import {
    continueAsClick,
    refreshUserProfile,
    saveProfileRequest,
    selectCurrentUserProfile,
    selectUser,
    signOut,
} from "../reducers/authSlice";
import {
    cgvStep, confirmMailStep,
    confirmPhoneStep,
    GreenPointDocStep,
    infoReminderStep,
    paymentStep,
    personalInfoStep,
    sumsubStep
} from "../components/nav/StepsData";
import {resetTunnel, setSteps} from "../reducers/tunnelSlice";
import Registration from "../components/registration/Registration";
import {NewCardType} from "../structs/apiConstants";
import {hasNonRejectedDocs, isGoneThroughSumsub} from "../Passeport";
import {isUserValid} from "../HelperFunctions";
import HeaderLight from "../components/global/HeaderLight";
import NavStepVerContainer from "./NavStepVerContainer";
import Header from "../components/global/Header";
import SaleTunnelWrapper from "../components/SaleTunnelWrapper"
import GAScripts from "../components/GAScripts";
import GTMScripts from "../components/GTMScripts";

const RegistrationContainer = (props) => {

    const saleId = parseInt(useParams().saleId);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const userProfile = useSelector(selectCurrentUserProfile);
    const restrictions = useSelector((state) => selectRestrictionsForSale(state, saleId));
    const registration = useSelector((state) => selectRegistrationForSale(state, saleId));
    const saleInfo = useSelector((state) => selectInfoForSale(state, saleId));

    useEffect(() => {
        dispatch(resetTunnel());
        if (isNaN(saleId)) {
            dispatch(registrationError("a correct sale must be provided"))
        } else {
            dispatch(getRegistrationForSaleRequest(saleId))
        }
    }, []);

    useEffect(() => {
        addRequiredStep();
    }, [restrictions]);


    const addRequiredStep = () => {
        const newSteps = [];
        if (restrictions && !registration) {
            if (!isUserValid(user)) {
                newSteps.push(confirmMailStep());
                newSteps.push(personalInfoStep());
                newSteps.push(cgvStep(saleId, saleInfo.conditions, saleInfo.cpName))
            } else {
                newSteps.push(infoReminderStep(saleId, userProfile, user.email, saleInfo.conditions, saleInfo.cpName, saleInfo.cpType,() => dispatch(saveProfileRequest())));
            }
            if (restrictions.validPhone && (!userProfile.phoneConfirmed)) {
                newSteps.push(confirmPhoneStep());
            }
            if (restrictions.validPaymentCard && restrictions.deposit === 0 && userProfile.creditCards.length === 0) {
                // if a security deposit is required registering a valid payment card should be made later.
                newSteps.push(paymentStep(NewCardType, 2));
            }
            if (restrictions.validDocKyc === "ANY") {
                if (!isGoneThroughSumsub(userProfile)) newSteps.push(sumsubStep(saleId));
                if (!hasNonRejectedDocs(userProfile)) newSteps.push(GreenPointDocStep(saleId));
            }
            dispatch(setSteps(newSteps));
        }
    };

    return (
        <>
            <GAScripts/>
            <GTMScripts/>
            {!registration ? <HeaderLight/> : <Header signOut={() => dispatch(signOut())}/>}
            {saleInfo && restrictions &&
                <SaleTunnelWrapper saleInfo={saleInfo} userCountry={userProfile.country} registration={registration} restrictions={restrictions} titleLabel={"label.inscription.vente"}>
                    {!registration && restrictions &&
                    <NavStepVerContainer
                        updateForm={props.updateForm}
                        endAction={() => getRegistrationForSaleRequest(saleId)}
                    />
                    }
                    {registration && <Registration
                        registration={registration}
                        restrictions={restrictions}
                        refreshUserProfile={() => dispatch(refreshUserProfile())}
                        refreshRegistration={() => dispatch(getRegistrationForSaleRequest(saleId))}
                        saleId={saleId}
                        saleInfo={saleInfo}
                        userProfile={userProfile}
                        updateForm={props.updateForm}
                        return={() => dispatch(continueAsClick())}/>}
                </SaleTunnelWrapper>
            }
        </>
    );
};

export default RegistrationContainer