import React, {useEffect, useState} from 'react'
import PhoneInput, {formatPhoneNumberIntl, isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import InnerTextField from "./InnerTextField";
import {FormattedMessage, useIntl} from "react-intl";
const PhoneField = (props) => {

    const [phone, setPhone] = useState(undefined);
    const [country, setCountry] = useState("");
    const [error, setError] = useState(false);
    const intl = useIntl()

    const onChangePhone = (value) => {
        const e = {target: {}};
        e.target.name = 'phone';
        if (value && isPossiblePhoneNumber(value) && isValidPhoneNumber(value)) {
            if (error) setError(false);
            value = formatPhoneNumberIntl(value);
            e.target.value = value.replace("+", "").replaceAll(" ", "");
            props.onInputErrorChanged(false);
        } else {
            if (!error) setError(true);
            e.target.value = ""
            props.onInputErrorChanged(true);
        }
        setPhone(value);
        props.onChange(e);
    };

    useEffect(() => {
        setCountry(intl.locale.replace("en", "gb").replace("zh", "cn").toUpperCase());
        if (props.phone) {
            setPhone("+" + props.phone)
        }
    },[props.phone]);

    return (
        <PhoneInput
            className="test-black"
            placeholder="+33 6 01 02 03 04"
            defaultCountry={country}
            onCountryChange={(c) => {if (c) setCountry(c)}}
            label={<FormattedMessage id="label.telephone"/>}
            value={phone}
            onChange={onChangePhone}
            countryCallingCodeEditable={false}
            international={true}
            error={error}
            inputComponent={InnerTextField}
        />
    )
};

export default PhoneField;