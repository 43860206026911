import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import {TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import SelectCountry from "../tools/SelectCountry";
import ConfirmPhoneContainer from "../../containers/ConfirmPhoneContainer";
import IconTick from "../tools/IconTick";
import CenteredModal from "../tools/CenteredModal";
import PhoneField from "../tools/PhoneField";
import ProfileField from "./ProfileField";


const ProfileAddress = (props) => {
    const [openPhoneModal, setOpenPhoneModal] = useState(false);
    const [newPhone, setNewPhone] = useState("");
    const [newCountry, setNewCountry] = useState("");

    useEffect(() => {
        setNewPhone(props.currentUserProfile.phone)
    }, [props.currentUserProfile.phone]);

    useEffect(() => {
        if (props.currentUserProfile.phoneConfirmed && openPhoneModal) {
            closeModal();
        }
    }, [props.currentUserProfile.phoneConfirmed]);

    useEffect(() => {
        setNewCountry(props.currentUserProfile.country)
    }, [props.currentUserProfile.country]);

    const closeModal = () => {
        props.refreshUserProfile();
        setOpenPhoneModal(false)
    };


    return (
        <>
            <CenteredModal
                openModal={openPhoneModal}
                onCloseModal={closeModal}
            >
                <ConfirmPhoneContainer updateForm={props.onChange}/>
            </CenteredModal>
            <h2 className="mt-2 text-xl dark:text-gray-300 font-extrabold capitalize">
                <FormattedMessage id="label.adresse"/>
            </h2>
                <ProfileField key={`address.${props.currentUserProfile.address}`}
                              label="label.adresse"
                              inputLabel="label.adresse"
                              name="address" value={props.currentUserProfile.address} onChange={props.onChange}/>

                <ProfileField key={`addresscomplement.${props.currentUserProfile.additionalAddress}`}
                              inputLabel="label.addresscomplement" name="additionalAddress"
                              value={props.currentUserProfile.additionalAddress} onChange={props.onChange}
                              optional={true}/>

                <ProfileField key={`zipCode.${props.currentUserProfile.zipCode}`} inputLabel="label.zipcode"
                              name="zipCode" value={props.currentUserProfile.zipCode} onChange={props.onChange}/>

                <ProfileField key={`city.${props.currentUserProfile.city}`} inputLabel="label.city" name="city"
                              value={props.currentUserProfile.city} onChange={props.onChange}/>


            <div className="inline-block mt-2 w-0 sm:w-2/6 align-top"/>
            <div className="inline-block mt-2 w-5/6 sm:w-3/6">
                <SelectCountry onChange={e => {
                    setNewCountry(e.target.value);
                    props.onChange(e);
                }} defaultValue={props.currentUserProfile.country}/>
            </div>
            <div className="inline-block pl-1 w-1/6 text-center align-bottom h-8"><IconTick isValid={props.currentUserProfile.country !== "" } /></div>

            {newCountry !== '75' &&
                <ProfileField key={`state.${props.currentUserProfile.state}`} inputLabel="label.addressstate" name="state" value={props.currentUserProfile.state} onChange={props.onChange} optional={true} />
            }

                <div className="inline-block mt-2 w-full sm:w-2/6 pr-1 align-top pt-2 text-gray-500">
                    <label><FormattedMessage id="label.telephone"/></label></div>

                <div className="inline-block mt-2 w-5/6 sm:w-3/6">
                    <PhoneField
                        onChange={(e) => {
                            setNewPhone(e.target.value);
                            props.onChange(e);
                        }}
                        phone={props.currentUserProfile.phone}
                        onInputErrorChanged={(e) => {
                            //No need to block anything here as we are on profile page
                            //Unlike modal where we disable the resend button
                        }}

                    />
                </div>

            {newPhone === props.currentUserProfile.phone &&
                <div className="inline-block mt-2 w-1/6 sm:w-1/6 text-center h-8 align-bottom pl-1">
                    <span className="px-2 font-medium">
                        <span className={props.currentUserProfile.phoneConfirmed ? "text-green-500" : "text-yellow-500 text-xs"}>
                            <FontAwesomeIcon icon={props.currentUserProfile.phoneConfirmed ? faCheckCircle : faExclamationCircle} />
                            <button onClick={() => setOpenPhoneModal(true)} className="underline text-blue-500 pl-1 text-xs">
                                {!props.currentUserProfile.phoneConfirmed && <FormattedMessage id="label.confirm" />}
                            </button>
                        </span>
                    </span>
                </div>
                }
            <div className="clear"/>

        </>
    )
};

ProfileAddress.propTypes = {
    onChange: PropTypes.func,
    saveProfile: PropTypes.func,
    changePassword: PropTypes.func,
    backToSignedIn: PropTypes.func,
    uploadDocuments: PropTypes.func,
    confirmPhone: PropTypes.func,
    signOut: PropTypes.func,
    email: PropTypes.string,
    isValidUser: PropTypes.bool,
    isFederatedUser: PropTypes.bool,
    currentUserProfile: PropTypes.object,
};

export default ProfileAddress;