import { AwsConfigMoniteurRecette } from "./awsConfigMoniteurRecette";
import { AwsConfigDrouotRecette } from "./awsConfigDrouotRecette";
import { AwsConfigMoniteur } from "./awsConfigMoniteur";
import { AwsConfigDrouot } from "./awsConfigDrouot";
import config from "../config";

const awsConfig = {
    awsConfigMoniteur: config.isRecette ? AwsConfigMoniteurRecette : AwsConfigMoniteur,
    awsConfigDrouot: config.isRecette ? AwsConfigDrouotRecette : AwsConfigDrouot,
};

export default awsConfig;