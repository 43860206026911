import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";


const IconTick = (props) => {
    return (
        <span className={`text-${props.isValid ? "green" : "red"}-500`}><FontAwesomeIcon icon={props.isValid ? faCheckCircle : faTimesCircle} /></span>
    )
};

IconTick.propTypes = {
    currentUserProfile: PropTypes.object,
    isValid: PropTypes.bool,
};

export default IconTick;

