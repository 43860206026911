import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import React from "react";

const CpContactInfo = props => (
    <div>
        <span className="text-base">
            {props.additionalTitleId &&
            <>
                <span className="bold"><FormattedMessage id={props.additionalTitleId} /></span>
                <br/>
            </>}
            {props.cp.name}
            <br/>
        </span>
        {props.cp.address}<br/>
        <FormattedMessage id="common.phone"/> : {props.cp.phone}<br/>
        <FormattedMessage id="common.contact"/> : {props.cp.mail}<br/>
    </div>
);

CpContactInfo.propTypes = {
    cp: PropTypes.object.isRequired,
    additionalTitleId: PropTypes.string,
};

export default CpContactInfo;