import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import {FormattedMessage} from "react-intl";
import IconTick from "../tools/IconTick";
import CpContactInfo from "../CpContactInfo";

const ValidRegistration = (props) => {
    const [depositAmount, setDepositAmount] = useState(0);

    const onChangeDeposit = (e) => {
        const newDeposit = parseInt(e.target.value);
        if (isNaN(newDeposit)) {
            setDepositAmount(0)
        } else {
            setDepositAmount(newDeposit);
        }
    };

    // Formatted start deposit date (dd/mm/yyyy)
    const formattedDepositStartDate = props.depositStartDate ?
        (("0" + props.depositStartDate.getDate()).slice(-2) + "/" +
            ("0" + (props.depositStartDate.getMonth() + 1)).slice(-2) + "/" +
            props.depositStartDate.getFullYear()) : null

    const newLimit = props.remainingLimit + Math.trunc(depositAmount * 100 / props.coverage);
    return props.depositStartDate && Date.now() < props.depositStartDate.getTime() && props.hasRemainingLimit ? (
        <div className="mt-4 mb-4 h-full">
            <h3 className="bold">
                <FormattedMessage id="label.bidding.limit.increase"/>
            </h3>

            <div className="flex flex-col h-4/6 justify-between my-8">
                <div className="flex-auto">
                        <span>
                            <FormattedMessage
                                id="label.bidding.limit.increase.start"
                                values={{date: formattedDepositStartDate}}
                            />
                        </span>
                </div>
            </div>
        </div>
    ) : (
        <div className="mt-4 mb-4">
            <h2 className="bold">
                <FormattedMessage id="label.valide"/> <IconTick isValid/>
            </h2>
            {!props.hasRemainingLimit &&
                <span className="text-base"><FormattedMessage id="label.registration.valid"/></span>
            }
            {props.hasRemainingLimit && <div className="my-3">
                {props.remainingLimit < 0 && <>
                    <p>
                        <span className="text-base"><FormattedMessage id="label.registration.remaining.max.limit"
                                                                      values={{
                                                                          biddingLimit: props.biddingLimit,
                                                                          currency: props.saleCurrency
                                                                      }}/></span>
                    </p>
                    <p>
                        <span className="text-base"><FormattedMessage
                            id="label.registration.remaining.max.limit.not.enough"/></span>
                    </p>
                    {props.hasIngenico && <>
                        <p>
                        <span className="text-base"><FormattedMessage
                            id="label.registration.remaining.max.limit.not.enough.cover.autobids"
                            values={{minimumAmount: -props.remainingLimit * props.coverage / 100}}/></span>
                        </p>
                        <p>
                        <span className="text-base font-semibold"><FormattedMessage
                            id="label.registration.remaining.transaction.not.charged"/></span>
                        </p>
                    </>}
                </>}
                {props.remainingLimit >= 0 &&
                    <div className="text-lg dark:text-gray-300 font-semibold"><FormattedMessage
                        id="label.your.limit"/> {props.remainingLimit} {props.saleCurrency} </div>
                }
                {props.hasIngenico ?
                    <>
                        {props.remainingLimit >= 0 &&
                            <div>
                                <FormattedMessage id="label.plafond.explain"/><br/>
                                <FormattedMessage id="label.plafond.auto.increase"/><br/>
                            </div>
                        }
                        <br/>
                        <div>{props.cardSelector}</div>
                        <br/>
                        <div>
                            <TextField
                                label={<FormattedMessage id="label.amount"/>}
                                id="amount"
                                variant="filled"
                                size="small"
                                onChange={onChangeDeposit}
                                value={depositAmount}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                            />
                        </div>
                        <br/>
                        <div>
                            <FormattedMessage id="label.plafond.rule" values={{
                                coverage: props.coverage,
                                exampleValue: 1000 * props.coverage / 100,
                            }}/>
                        </div>
                        <br/>
                        {props.remainingLimit >= 0 && newLimit >= 0 &&
                            <div className="bold text-base">
                                <FormattedMessage id="label.plafond.new" values={{
                                    newLimit: newLimit,
                                }}/>
                            </div>
                        }
                        <Button disabled={depositAmount <= 0} variant="contained" color="primary"
                                onClick={() => props.setPaymentModal(depositAmount)}>
                            <FormattedMessage id="label.augmenter.mon.plafond"/>
                        </Button>
                        <br/>
                    </> :
                    <>
                        <div className="text-base">
                            <FormattedMessage id="label.plafond.explain"/><br/>
                        </div>
                        <br/>
                        <CpContactInfo cp={props.cp} additionalTitleId="label.plafond.manual.increase"/>
                    </>}
            </div>}
        </div>
    )
};


ValidRegistration.propTypes = {
    saleCurrency: PropTypes.number,
    hasRemainingLimit: PropTypes.bool,
    hasIngenico: PropTypes.bool,
    remainingLimit: PropTypes.number,
    biddingLimit: PropTypes.number,
    setPaymentModal: PropTypes.func,
    coverage: PropTypes.number,
    cardSelector: PropTypes.element,
    cp: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        mail: PropTypes.string,
        address: PropTypes.string,
    }),
};


export default ValidRegistration