import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import StepTitle from "../components/nav/StepTitle";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {updateFormInputs, continueAsClick} from "../reducers/authSlice";
import {
    selectActiveStep,
    selectCanValidateStep,
    selectSteps,
    selectStepSuccess,
    setActiveStep,
    nextActiveStep,
    stepSucceeded,
    setCanValidateStep,
    resetTunnel,
} from "../reducers/tunnelSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
}), {index: 1});

const NavStepVerContainer = props => {
    const classes = useStyles();
    const activeStep = useSelector(selectActiveStep);
    const canValidateStep = useSelector(selectCanValidateStep);
    const steps = useSelector(selectSteps);
    const success = useSelector(selectStepSuccess);
    const dispatch = useDispatch();
    const lastStepLabel = props.lastStepLabel ? props.lastStepLabel : "label.validate.registration"

    useEffect(() => {
        dispatch(setCanValidateStep(false));
    }, [activeStep]);

    useEffect(() => {
        if (success) {
            if (isLastStep()) {
                dispatch(props.endAction());
            } else {
                dispatch(nextActiveStep());
            }
        }
    }, [success]);

    const stepsComponent = steps.map(step => (
        <StepTitle titleId={step.stepTitle}/>
    ));

    const handleNext = (...payload) => {

        const validator = steps[activeStep].validatorAction;
        if (validator) {
            dispatch(steps[activeStep].validatorAction({...payload, isLastStep: isLastStep(), endAction: props.endAction}))
        } else {
            dispatch(stepSucceeded())
        }
    };

    const handleBack = () => {
        dispatch(setActiveStep(activeStep-1));
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const totalSteps = () => {
        return stepsComponent.length;
    };

    const getStepContent = (index) => {
        const step = steps[index];
        return (
            <div className="mt-4 mb-4">
                {React.cloneElement(step.component, {
                    canValidateStep: canValidateStep,
                    setCanValidateStep: (canValidate) => dispatch(setCanValidateStep(canValidate)),
                    updateForm: props.updateForm,
                    rawUpdateInput: (name, value) => dispatch(updateFormInputs({name, value})),
                })}
            </div>
        )
    };
    
    const cancel = () => {
        dispatch(continueAsClick());
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {stepsComponent.map((label, index) => (
                    <Step key={label}>
                        <StepLabel className="text-lg">{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        onClick={cancel}
                                        variant="contained"
                                        className={classes.button}
                                    >
                                        <FormattedMessage id="label.back"/>
                                    </Button>
                                    {/*<Button*/}
                                    {/*    disabled={activeStep === 0}*/}
                                    {/*    onClick={handleBack}*/}
                                    {/*    className={classes.button}*/}
                                    {/*>*/}
                                    {/*    <FormattedMessage id="label.back"/>*/}
                                    {/*</Button>*/}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!canValidateStep}
                                    >
                                        {steps[activeStep].validatorButtonId ?
                                            <FormattedMessage id={steps[activeStep].validatorButtonId}/> :
                                            isLastStep() ? <FormattedMessage id={`${lastStepLabel}`}/> : <FormattedMessage id="common.next"/>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === stepsComponent.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography><FormattedMessage id="label.steps.completed"/></Typography>
                </Paper>
            )}
        </div>
    );
};


NavStepVerContainer.propTypes = {
    updateForm: PropTypes.func.isRequired,
    endAction: PropTypes.func,
    lastStepLabel: PropTypes.string,
};

export default NavStepVerContainer;
