import {call, put, takeLatest, select, race, take, delay, all} from 'redux-saga/effects';
import {
    createPaymentRequest,
    createPaymentSuccess,
    initHostedTokenization,
    initHostedTokenizationSuccess,
    paymentError,
    checkPaymentStatus,
    checkPaymentStatusSuccess,
    checkUserCard,
    checkUserCardSuccess,
} from "../reducers/paymentSlice";
import {
    selectUser,
} from "../reducers/authSlice";
import {
    resetTunnel, stepSucceeded,
} from "../reducers/tunnelSlice";
import {getOrPostToAuthenticatedRoute, makeGringottsAuthenticatedCallToRoute} from "./helper";
import config from "../config";
import {apiTypeFromValue, DepositType, NewCardType, SecurityDepositType} from "../structs/apiConstants";
import {push} from "connected-react-router";

function* initHostedTokenizationSaga(action) {
    const pspid = action.payload.pspid;
    const user = yield select(selectUser);
    try {
        const resp = yield call(makeGringottsAuthenticatedCallToRoute, user, `/tokenize?pspid=${pspid}&platform=${config.site}&locale=${action.payload.locale}_${action.payload.locale.toUpperCase()}`, false);
        if (resp.status !== 200) {
            yield call(handleError, resp);
            return
        }
        const data = yield resp.json();
        const actionData = {
            tokenization: {
                hostedTokenizationId: data.hostedTokenizationId,
                hostedTokenizationUrl: data.hostedTokenizationUrl,
            }
        }
        yield put(initHostedTokenizationSuccess(actionData));
    } catch (e) {
        console.error({e});
        yield put(paymentError("payment.status.error"))
    }
}

function* createPaymentSaga(action) {
    const user = yield select(selectUser);
    const {pspid, timezoneOffsetUtcMinutes, locale, browserData, type, isTunnel, amount, saleId, selectedCard, cvv, hostedTokenizationId} = action.payload;
    try {
        let returnUrl = `${window.location.origin}/waiting-payment?type=${type}&isTunnel=${!!isTunnel}`;
        if (saleId) {
            returnUrl = `${returnUrl}&saleId=${saleId}`;
        }
        const body = {amount: amount * 100, pspid: pspid, saleId: saleId, platform: config.site, returnUrl: returnUrl};
        if (hostedTokenizationId) {
            body.hostedTokenizationId = hostedTokenizationId;
        } else {
            body.token = selectedCard;
            body.cvv = cvv;
        }
        body.timezoneOffsetUtcMinutes = timezoneOffsetUtcMinutes;
        body.locale = locale;
        body.browserData = browserData;

        const resp = yield call(makeGringottsAuthenticatedCallToRoute, user, `/${apiTypeFromValue(type)}`, true, body);
        if (resp.status !== 200) {
            yield call(handleError, resp);
            return
        }
        const data = yield resp.json();
        if (data.redirectUrl && data.actionType === "REDIRECT") {
            const actionData = {
                isSuccess: data.isSuccess,
                redirectUrl: data.redirectUrl,
                actionType: data.actionType,
                isTunnel: isTunnel,
            }
            if (isTunnel) {
                yield all([put(createPaymentSuccess(actionData)), yield put(checkUserCard())]);
                return
            }
            yield put(createPaymentSuccess(actionData));
            return
        }
        const actionData = {
            payId: data.paymentId,
            isTunnel: isTunnel,
            saleId: saleId,
            payType: type,
        }
        actionData.success = `payment.status.success.${apiTypeFromValue(type)}`;
        yield put(checkPaymentStatus(actionData));
    } catch (e) {
        console.error({e});
        yield put(paymentError("payment.status.error"))
    }
}

function* checkUserCardPollSaga() {
    const user = yield select(selectUser);
    while (true) {
        try {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, "/users");
            if (resp.status !== 200) {
                yield put(paymentError(resp.statusText));
            } else {
                const data = yield resp.json();
                if (data.creditCards && data.creditCards.length) {
                    yield all([yield put(stepSucceeded()), yield put(checkUserCardSuccess({success:`payment.status.success.alias`}))]);
                }
            }
            yield delay(2000)
        } catch (e) {
            console.error({e});
            yield put(paymentError(e))
        }
    }
}

function* checkPaymentSucceededSaga(action) {
    const user = yield select(selectUser);
    const {payId: payId, payType: payType, saleId: saleId, isTunnel: isTunnel} = action.payload;
    while (true) {
        try {
            const resp = yield call(makeGringottsAuthenticatedCallToRoute, user, `/status?paymentId=${payId}&platform=${config.site}`, false);
            if (resp.status !== 200) {
                return yield call(handleError, resp);
            }

            const data = yield resp.json();
            if (data.status === "PENDING") {
                yield delay(2000);
            }

            if (data.status === "VALID") {
                const successMessage = `payment.status.success.${apiTypeFromValue(payType)}`;
                if (!isTunnel) {
                    if (payType === DepositType || payType === SecurityDepositType) {
                        return yield all([put(checkPaymentStatusSuccess({success: successMessage})), yield put(push(`/registration/${saleId}`))]);
                    } else if (payType === NewCardType) {
                        return yield all([put(checkPaymentStatusSuccess({success: successMessage})), yield put(push(`/profile/cards`))]);
                    }
                    return
                }
                return yield put(checkPaymentStatusSuccess({success: successMessage + '.tunnel'}));
            }
            if (data.status === "INVALID") {
                return yield put(paymentError("payment.status.error"))
            }
        } catch (e) {
            console.error({e});
            yield put(paymentError("payment.status.error"))
        }
    }
}


function* startAndWatchCheckUserCardPollSaga() {
    yield race([call(checkUserCardPollSaga), take([resetTunnel, checkUserCardSuccess, paymentError])])
}


function* startAndWatchCheckPaymentSucceededSaga(action) {
    yield race([call(checkPaymentSucceededSaga, action), take([checkPaymentStatusSuccess, paymentError])])
}

function* watchPaymentSaga() {
    yield takeLatest(initHostedTokenization, initHostedTokenizationSaga);
    yield takeLatest(createPaymentRequest, createPaymentSaga);
    yield takeLatest(checkUserCard, startAndWatchCheckUserCardPollSaga);
    yield takeLatest(checkPaymentStatus, startAndWatchCheckPaymentSucceededSaga);
}

function* handleError(resp) {
    if (resp.json) {
        const data = yield resp.json();
        yield put(paymentError(data.messageCode || data.message));
        return
    }
    yield put(paymentError(resp.statusText));
}

export default watchPaymentSaga;