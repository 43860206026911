import React, {useState} from "react";
import PropTypes from 'prop-types';
import {checkAndSetCanValidateStep} from "../HelperFunctions";
import {FormattedMessage} from "react-intl";
import config from "../config";
import CenteredModal from "./tools/CenteredModal";

const Cgv = (props) => {
    const [checked, setChecked] = useState({});
    const [openModal, setOpenModal] = useState(false);

    const onChange = (e) => {
        if (props.onChange) {
            // means it's included in info reminder screen, so no need for locale check
            props.onChange(e);
            return
        }
        const newChecked = {...checked, [e.target.name]: e.target.checked};
        setChecked(newChecked);
        checkAndSetCanValidateStep(newChecked, ["cgv", "cgu", "cgd"], props.canValidateStep, props.setCanValidateStep);
    };

    const saleConditionEscape = () => {
        const doc = new DOMParser().parseFromString(props.saleConditions, "text/html");
        return doc.documentElement.innerHTML;
    };
    let cguToUse = "info.cgu.1"
    let cgdToUse = "info.cgd.1"
    if (props.cpType === "STOCKER" || props.cpType === "COLLECTIVITY") {
        cguToUse = "info.cgu.vendeur.1"
        cgdToUse = "info.cgd.vendeur.1"
    }
    return (
        <>
            <CenteredModal openModal={openModal} onCloseModal={() => setOpenModal(false)}>
                <div className="w-full pt-2" dangerouslySetInnerHTML={{__html:saleConditionEscape()}}></div>
            </CenteredModal>
            <div className="text-xs">
                <label className="mt-4">
                    <input id="cgu" name="cgu" type="checkbox" className="mr-2" onChange={onChange}/>
                    <FormattedMessage id={cguToUse}/> <a href={`https://${config.domain}/online/publicShowConditions`} target="_blank" rel="noopener noreferrer" className="underline"><FormattedMessage id={`info.cgu.condition.utilisation.${config.site}`}/></a>.*<br/><br/>
                </label>
                <div className="clear"/>
                <label>
                    <input id="cgv" name="cgv" type="checkbox" className="mr-2" onChange={onChange}/>
                    <FormattedMessage id="info.cgv.1"/> <FormattedMessage id="info.cgv.conditions"/> <button onClick={() => setOpenModal(true)} className="underline">{props.cpName.toUpperCase()}</button>.*<br/>
                    <br/>
                </label>
                <div className="clear"/>
                <label className="mt-4">
                    <input id="cgd" name="cgd" type="checkbox" className="mr-2" onChange={onChange}/>
                    <FormattedMessage id={cgdToUse}/>*<br/>
                </label>
                <br/>
                <span className="mt-4"><FormattedMessage id="info.cgd.2"/></span>
            </div>
        </>
    )
};

Cgv.propTypes = {
    cpName: PropTypes.string,
    cpType: PropTypes.string,
    saleConditions: PropTypes.string,
    onChange: PropTypes.func,
};

export default Cgv;