import React from "react";
import config from "../../config";
import {useIntl} from "react-intl";

const HeaderLight = (props) => {
    const intl = useIntl()
    return (
        <div className="container mx-auto px-2 sm:px-0 h-full">
            <div className="w-full ml-auto mr-auto">
                <a href={`https://${config.domain}/${intl.locale}`}><div className={`logo-${config.site} bg-center my-8`}/></a>
            </div>
        </div>
    )
};

export default HeaderLight;