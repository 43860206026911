import {getOdinCookie, setOdinCookie} from "./sagas/authSagas";
import {refreshToken} from "./odin/odin";

export const checkAndSetCanValidateStep = (newInputs, mandatoryFields, canValidateStep, setCanValidateStep, setHasInvalidCharacters = () => {}) => {
    const notValid = mandatoryFields.some(field => !newInputs[field] || newInputs[field] === "");
    const invalidValues = mandatoryFields.some(field => hasInvalidCharacters(newInputs[field]));
    if (notValid || invalidValues) {
        setHasInvalidCharacters(invalidValues);
        if (canValidateStep) setCanValidateStep(false);
        return
    }
    setHasInvalidCharacters(false);
    setCanValidateStep(true);
};

export const isUserValid = (user) => user && user.sub && user?.custom?.firstName && user?.custom?.lastName;
export const isCognitoUserValid = (user) => user !== null && user.attributes !== undefined && (user.attributes.email_verified || user.attributes.identities !== undefined) && user.attributes["custom:pass_id"] !== undefined;

/**
 Check the word only contains accepted characters
 Accepted = ASCII code between 32 and 253 (both included)
 */
export const hasInvalidCharacters = word => {
    if (!word || typeof word === "boolean" || typeof word === "object") return false;
    return word.split("").map(c => c.charCodeAt(0)).some(code => code < 32 || code > 253)
}

export const _parseJwt = function (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const isTokenStillValid = (token) => {
    if (!token) return false;
    const decoded = _parseJwt(token);
    const now = Math.round(new Date().getTime() / 1000);
    const twoMinutes = 60 * 2;
    return decoded.exp - now > twoMinutes;
}

export let getAccessToken = async () => {
    let accessToken = getOdinCookie("access")
    if (!accessToken || !isTokenStillValid(accessToken)) {
        const refresh = getOdinCookie("refresh")
        if (refresh === null) {
            return null
        }
        try {
            const refreshResponse = await refreshToken(refresh);
            setOdinCookie(refreshResponse.accessToken.val, "access", refreshResponse.accessToken.exp);
            accessToken = refreshResponse.accessToken.val;
        } catch (e) {
            console.error("Error refreshing token", e)
            return null
        }
    }
    return accessToken;
};

export const getTokenFromQueryParam = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('token');
};