import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignedInPage from "../components/SignedInPage";
import {
    selectUser,
    continueAsClick,
    signOut,
} from "../reducers/authSlice";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";

const SignedInPageContainer = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    return (
        <>
            <Header signOut={() => dispatch(signOut())} />
            <SignedInPage
                user={user}
                signOut={() => dispatch(signOut())}
                continueAsToWebsite={() => dispatch(continueAsClick())}
            />
            <Footer/>
        </>
    )
};

export default SignedInPageContainer;