import {CircularProgress, Modal} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const LoadingModal = (props) => {
    return (
        <Modal
            open={props.loading}
            onClose={() => {}}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display:'flex flex-col',alignItems:'center',justifyContent:'center'}}
        >
            <div className="h-full flex flex-row justify-center items-center">
                <CircularProgress size={80} thickness={4.5}/>
            </div>
        </Modal>
    )
};

LoadingModal.propTypes = {
    loading: PropTypes.bool,
};

export default LoadingModal;