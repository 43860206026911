import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {signUpRequest} from "../reducers/authSlice";
import SignUpForm from "../components/SignUpForm";
import HeaderLight from "../components/global/HeaderLight";
import GAScripts from "../components/GAScripts";
import GTMScripts from "../components/GTMScripts";

const SignUpFormContainer = (props) => {
    const dispatch = useDispatch();
    return (
        <>
            <GAScripts/>
            <GTMScripts/>
            <HeaderLight/>
            <SignUpForm
                onChange={props.updateForm}
                signUp={() => dispatch(signUpRequest())}
            />
        </>
            )
};

SignUpFormContainer.propTypes = {
    updateForm: PropTypes.func,
};

export default SignUpFormContainer;