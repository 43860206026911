import React from "react";
import {FormattedMessage} from "react-intl";
import IconTick from "../tools/IconTick";

const InvalidRegTitle = (props) => {
    return (
        <>
            <h3 className="bold">
                {props.registrationStatus === 0 ? <FormattedMessage id="label.registration.refused"/>  :
                    !props.invalidConditions ?
                    <FormattedMessage id="label.registration.awaiting"/> :
                    <FormattedMessage id="label.invalid"/>
                }
            </h3>
            <>{props.registrationStatus === 0 ? <FormattedMessage id="label.registration.refused.text"/> : !props.invalidConditions && !props.isFiltered ?
                <span className="text-lg my-2">
                    <FormattedMessage id="label.registration.wait"/>
            </span> :
                ""
            }
            </>
        </>
    )
};

export default InvalidRegTitle
