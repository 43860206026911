import {call, put} from "redux-saga/effects";
import config from "../config";
import {getOdinCookie, setOdinCookie} from "./authSagas";
import {getAccessToken, isTokenStillValid} from "../HelperFunctions";
import {push} from "connected-react-router";
import {refreshToken} from "../odin/odin";

export function *getOrPostToAuthenticatedRoute(authenticatedUser, route, post=false, newData={}) {
    // todo force refresh to avoid 401 ?
    const accessToken = yield call(getAccessToken);
    const requestConfig = {
        method: post ? "POST" : "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        },
    };
    if (post) {
        requestConfig.body= JSON.stringify(newData)
    }
    return yield call(fetch, config.gingolemApi + route, requestConfig);
}

export function *makeGringottsAuthenticatedCallToRoute(authenticatedUser, route, post = false, data = {}) {
    const accessToken = yield call(getAccessToken);
    const request = {
        method: post ? "POST" : "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
    };
    if (post) {
        request.headers.Accept = "application/json";
        request.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    if (post) {
        request.body = JSON.stringify(data);
    }
    return yield call(fetch, config.gringottsApi + route, request)
}

export function *dispatchErrorAccordingToStatus(status, errorCode, errorAction) {
    yield put(errorAction(status === 401 ? "Unauthorized" : errorCode));

}