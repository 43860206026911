import React from "react";
import {
    useSelector
} from "react-redux";
import {
    selectPaymentLoading,
} from "../reducers/paymentSlice";
import {
    selectLoading as selectAuthLoading
} from "../reducers/authSlice";
import {
    selectLoading as selectRegistrationLoading
} from "../reducers/registrationSlice";
import {
    selectLoading as selectCgvLoading
} from "../reducers/cgvSlice";
import LoadingModal from "../components/tools/LoadingModal";
import {
    selectDocumentLoading
} from "../reducers/documentSlice";

const LoadingContainer = () => {
    const regLoading = useSelector(selectRegistrationLoading);
    const payLoading = useSelector(selectPaymentLoading);
    const authLoading = useSelector(selectAuthLoading);
    const docLoading  = useSelector(selectDocumentLoading);
    const cgvLoading = useSelector(selectCgvLoading);

    return (
        <LoadingModal loading={regLoading || payLoading || authLoading || docLoading || cgvLoading} />
    )
};

export default LoadingContainer;