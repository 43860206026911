import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import {FormattedMessage, useIntl} from "react-intl";
import config from "../../config";

const Footer = (props) => {
    const intl = useIntl()
    const domain = `https://${config.domain}/${intl.locale}`

    return (
    <footer className={`w-full mt-20 font13 footer${config.site}`}>
            <div className="container relative mx-auto py-12">
                <div className="flex flex-wrap">
                    <div className="w-full ml-auto mr-auto">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-3/12 pr-3">
                                <div className={`logo-${config.site}_footer`}/>
                                <div><FormattedMessage id={`footer.${config.isMoniteur ? "moniteur" : "drouot" }`}/></div>
                                <a href="https://www.moniteurdesventes.com" target="_blank" rel="noopener" className="no-more">
                                    <div className={`logo-${config.isMoniteur ? "drouot" : "moniteur" }_footer_noir mt-10`}/>
                                    <div className="font11"><FormattedMessage id={`footer.${config.isMoniteur ? "drouot" : "moniteur"}`}/></div>
                                </a>
                            </div>
                            <div className="w-full lg:w-3/12 pr-3 no-more">
                                <div className="bold">
                                    <a href=""><FormattedMessage id="label.pour.vendre"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/offer`}><FormattedMessage id="common.types.catalogue"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/offer`}><FormattedMessage id="common.types.live"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/offer`}><FormattedMessage id="common.types.online"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/offer`}><FormattedMessage id="common.types.web"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/offer`}><FormattedMessage id="common.types.marketing"/></a>
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 pr-3 no-more">
                                <div className="bold">
                                    <a href={`${domain}/faq`}><FormattedMessage id="label.pour.acheter"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/faq`}><FormattedMessage id="common.crer.compte"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/faq`}><FormattedMessage id="common.inscrire.vente"/></a>
                                </div>
                                <div>
                                    <a href={`${domain}/faq`}><FormattedMessage id="common.encherir"/></a>
                                </div>
                                <div><a
                                    href="https://www.conseildesventes.fr/content/acheter-en-vente-aux-encheres-publiques"
                                    target="_blank"
                                    rel="noopener"><FormattedMessage id="common.acheter.aux.encheres"/></a></div>
                                <div><a
                                    href="https://www.conseildesventes.fr/content/rappel-sur-les-ventes-de-gre-gre-des-invendus-aux-encheres"
                                    target="_blank"
                                    rel="noopener"><FormattedMessage id="common.acheter.de.gre.a.gre"/></a>
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 pr-3 no-more">
                                <div className="bold">
                                    <a href={`${domain}/about`}><FormattedMessage id="footer.apropos"/></a>
                                </div>
                                <div>
                                    <a href="https://www.drouot.com" target="_blank"
                                       rel="noopener"><FormattedMessage id="common.hotel.vente.drouot"/></a>
                                </div>
                                <div>
                                    <a href="https://www.moniteurdesventes.com" target="_blank"
                                       rel="noopener">Le Moniteur Live</a></div>
                                <div>
                                    <a href="https://www.gazette-drouot.com" target="_blank"
                                       rel="noopener">La Gazette Drouot</a></div>
                                <div>
                                    <a href={`${domain}/auctionners`}><FormattedMessage id="common.operateur.drouot.digital"/></a>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-6 border-b-1 border-gray-300" />
                        <div className="flex flex-wrap mt-6 relative">
                            <div className="w-1/2">
                                <FormattedMessage id="mypass.menu.copyright"/> © {config.isMoniteur ? "Moniteur Live" : "Drouot"}
                            </div>
                            <div className="w-1/2 text-right">
                                <a href={`${domain}/general-terms`}><FormattedMessage id="common.cgu"/></a> -
                                <a href={`${domain}/privacy-policy`}> <FormattedMessage id="common.politique.conf"/></a>
                                <a href="https://www.facebook.com/drouotparis?ref=hl" target="_blank" rel="noopener" className="no-more">
                                    <FontAwesomeIcon icon={faFacebook} className="ml-3" /></a>
                                <a href="https://twitter.com/Drouot" target="_blank" rel="noopener" className="no-more">
                                    <FontAwesomeIcon icon={faTwitter} className="ml-1" /></a>
                                <a href="https://www.instagram.com/drouot_paris/" target="_blank" rel="noopener" className="no-more">
                                    <FontAwesomeIcon icon={faInstagram} className="ml-1"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </footer>

    )
};

export default Footer;