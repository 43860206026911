import React, {useEffect} from "react";
import {
    confirmMailStep, confirmPhoneStep,
    congratulationProfileStep,
    personalInfoStep
} from "../components/nav/StepsData";
import NavStepHorContainer from "./NavStepHorContainer";
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUser,
    selectCurrentUserProfile,
    selectIsFederatedUser,
} from "../reducers/authSlice";

import {
    setSteps,
    resetTunnel,
} from "../reducers/tunnelSlice";
import {isUserValid} from "../HelperFunctions";

const TunnelContainer = props => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isFederatedUser = useSelector(selectIsFederatedUser);
    const currentUserProfile = useSelector(selectCurrentUserProfile);

    useEffect(() => {
        dispatch(resetTunnel());
    }, []);

    useEffect(() => {
        const stepsToGo = [];
        if (!user || (!user.email && !isFederatedUser)) {
            stepsToGo.push(confirmMailStep());
        }
        if (!user || !isUserValid(user)) {
            stepsToGo.push(personalInfoStep(currentUserProfile));
            stepsToGo.push(confirmPhoneStep());
        }
        stepsToGo.push(congratulationProfileStep());
        dispatch(setSteps(stepsToGo))
    }, [isFederatedUser]);

    return (
        <NavStepHorContainer
            updateForm={props.updateForm}
        />
    )
};

export default TunnelContainer;