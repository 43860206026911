import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
    },
}));

const SelectCard = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} noValidate>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
                <FormattedMessage id="common.credit.cards"/>
            </InputLabel>
            <NativeSelect
                InputLabelProps={{
                    shrink: true,
                }}
                name="creditCard"
                onChange={props.onChange}
            >

                {props.creditCards.map(card => (
                <option selected={props.defaultValue === card.alias} key={card.id} value={card.alias}>{card.description} - {card.date.substring(0, 10)}</option>
                ))}
                {props.addOption && <FormattedMessage id="label.add.cb" key="0">
                    {(message) => <option name="options" value="">{message}</option>}
                </FormattedMessage>}
            }
            </NativeSelect>
        </FormControl>
    )
};

SelectCard.propTypes = {
    onChange: PropTypes.func.isRequired,
    addOption: PropTypes.bool,
    creditCards: PropTypes.string,
    defaultValue: PropTypes.string,
};

export default SelectCard;