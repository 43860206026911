import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Input from "./tools/Input";
import { FormattedMessage } from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import config from "../config";
import {Link} from "react-router-dom";
import SocialLogin from "./tools/SocialLogin";
import Turnstile from "react-turnstile";
import { checkNeedsToken } from "../odin/odin";

const SignUpForm = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState();
    const [needsToken, setNeedsToken] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');

    useEffect(() => {
        checkNeedsToken().then((requiresToken) => {
            setNeedsToken(requiresToken);
        }).catch(e => {
            console.error("Error checking if we need token", e);
        });
    }, []);


    const onPassChange = (e, isConfirm) => {
        const pwdValue = e.target.value;
        if (isConfirm) {
            setConfirmPass(pwdValue)
        } else {
            setPassword(pwdValue);
            props.onChange(e)
        }
    };

    const onCaptchaTokenChange = (value) => {
        setCaptchaToken(value);
        const event = {target: {name: "captchaToken", value: value}};
        props.onChange(event);
    }

    return (
      <div className="py-6">
          <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto">
              <div className="hidden lg:block lg:w-1/2 bg-gray-400 shadow-lg p-10 rounded-lg overflow-hidden">
                  <div
                    className={`${config.isMoniteur ? "bg-moniteur" : "bg-drouot-4"} bg-no-repeat bg-contain max-w-sm md:max-w-lg h-60 mb-8`}/>
                  <h2 className="text-white text-3xl font-semibold"><FormattedMessage id="info.deja.compte"/></h2>
                  <div className="text-white text-xl mt-2 font-semibold mb-10">
                      <FormattedMessage id="info.identifiez.vous"/><br/>
                      <FormattedMessage id="info.profitez.avantages"/>
                  </div>
                  <Link to="/">
                      <button
                        className="bg-gray-700 text-white text-uppercase px-8 py-3 rounded font-weight-bold hover:bg-gray-800 transition">
                          <FontAwesomeIcon icon={faUserCircle} className="mr-2"/>
                          <FormattedMessage id="mypass.connexion"/>
                      </button>
                  </Link>
              </div>

              <div className="w-full p-8 lg:w-1/2">

                  <SocialLogin/>

                  <h2 className="text-2xl font-bold text-center"><FormattedMessage id="common.create.account"/></h2>

                  <div className="mt-4">
                      <label className="block text-sm font-bold mb-2"><FormattedMessage id="common.email"/></label>
                      <Input onChange={props.onChange}
                             name="email"
                             label={<FormattedMessage id="label.email"/>}/>
                  </div>
                  <div className="mt-4">
                      <div className="flex justify-between">
                          <label className="block text-sm font-bold mb-2"><FormattedMessage
                            id="common.password"/></label>
                      </div>
                      <FormattedMessage id="label.psw.choose">
                          {msg => <Input onChange={(e) => onPassChange(e, false)}
                                         name="password"
                                         type="password"
                                         placeholder={msg}
                          />}
                      </FormattedMessage>
                      <div className="text-xs my-2"><FormattedMessage id="label.password.rule"/></div>
                      <FormattedMessage id="label.psw.confirm">
                          {msg => <Input onChange={(e) => onPassChange(e, true)}
                                         name="passwordConfirm"
                                         type="password"
                                         placeholder={msg}
                          />}
                      </FormattedMessage>
                      {confirmPass && confirmPass !== password &&
                        <span className="text-sm text-red-500"><FormattedMessage id="error.same.pwd"/></span>}
                  </div>
                  <div className="flex mt-8 items-center justify-center">
                      {needsToken && <Turnstile
                        sitekey="0x4AAAAAAAxxxXCoMURfcuvu"
                        onVerify={(token) => {
                            onCaptchaTokenChange(token);
                        }}
                      />}
                  </div>
                  <div className="mt-8">
                      <button disabled={password !== confirmPass && (!needsToken || !captchaToken)} onClick={props.signUp}
                              className="w-full bg-red-600 text-white text-center text-uppercase px-8 py-3 rounded font-weight-bold hover:bg-red-800 transition">
                          <FormattedMessage id="common.create.account"/>
                      </button>
                  </div>
                  <div className="mt-4 flex items-center justify-between">
                      <span className="border-b w-1/5 md:w-1/4"></span>
                      <Link to="/">
                          <button className="text-xs text-gray-500 uppercase"><FormattedMessage
                            id="common.back.to.sign.in"/></button>
                      </Link>
                      <span className="border-b w-1/5 md:w-1/4"></span>
                  </div>
              </div>
          </div>
      </div>

    )
};

SignUpForm.propTypes = {
    onChange: PropTypes.func,
    signUp: PropTypes.func,
    backToSignInLink: PropTypes.func,
};

export default SignUpForm;