import config from "../config";
import {getTokenFromQueryParam} from "../HelperFunctions";

export class OdinError extends Error {
    messageCode
    constructor(message, messageCode, code) {
        super(message);
        this.code = code;
        this.messageCode = messageCode;
    }
}
export const signInApi = async (email, password) => {
    const response = await fetch(`${config.gingolemApi}/odin/sign-in`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
    });
    const responseData = await response.json();
    if (!response.ok) {
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }

    return responseData;
};

export const signInWithApple = async (data) => {
    const response = await fetch(`${config.gingolemApi}/odin/sign-in-with-apple`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok) {
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }

    return responseData;
};

export const refreshToken = async (refreshToken) => {
    const response = await fetch(`${config.gingolemApi}/odin/refresh`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`,
        },
    });
    const responseData = await response.json();
    if (!response.ok) {
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }

    return responseData;
}

export const pushPwd = async (password, accessToken) => {
    const response = await fetch(`${config.gingolemApi}/auth/set-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ password }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}

export const odinSignOut = async (refreshToken, accessToken) => {
    const response = await fetch(`${config.gingolemApi}/odin/sign-out`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ refreshToken }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}

export const signUp = async (email, password, captchaToken) => {
    const response = await fetch(`${config.gingolemApi}/odin/sign-up`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password, lang: "fr", captchaToken: captchaToken, captchaType: "turnstile" }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}

export const checkNeedsToken = async() => {
    const response = await fetch(`${config.gingolemApi}/odin/requires-captcha`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
    const responseData = await response.json();
    return responseData.required || false;
}

export const verifyMail = async (email, code, token) => {
    const response = await fetch(`${config.gingolemApi}/odin/confirm-with-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, code: code }),
    });
    const responseData = await response.json();
    if (!response.ok) {
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }

    return responseData;
}

export const changePassword = async (oldPwd, newPwd, accessToken) => {
    const response = await fetch(`${config.gingolemApi}/odin/change-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ oldPassword: oldPwd, newPassword: newPwd }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}
const getCurrentLocalLang = () => {
    let str = navigator.language;
    if (str.length < 2) {
        return str;
    }
    return str.substring(0, 2);
};

export const forgotPassword = async (email, lang) => {
    const response = await fetch(`${config.gingolemApi}/odin/forgot-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, lang }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}

export const resetPassword = async (newPassword) => {
    const forgotToken = getTokenFromQueryParam();
    const response = await fetch(`${config.gingolemApi}/odin/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${forgotToken}`,
        },
        body: JSON.stringify({ password: newPassword }),
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new OdinError(responseData.message, responseData.messageCode, response.status);
    }
}