import {Helmet} from "react-helmet";
import config from "../config";

const GAScripts = () => {
    if (config.isRecette) {
        return null;
    }
    const gaTagId = config.isMoniteur ? config.gaTagIdMoniteur : config.gaTagIdDrouot;
    if (!gaTagId) {
        return null;
    }
    return (
        <>
            <Helmet>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTagId}`}></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gaTagId}');
                    `}
                </script>
            </Helmet>
        </>
    )
}
export default GAScripts;