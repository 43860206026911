const fr = require("./fr");
const paysFr = {...require("./country/fr")};
Object.keys(paysFr).forEach(id => delete Object.assign(paysFr, {[`pays.${id}`]: paysFr[id] })[id])
const en = require("./en");
const de = require("./de");
const it = require("./it");
const es = require("./es");
const zh = require("./zh");

const Message = {
    fr: {...fr, ...paysFr},
    en,
    de,
    it,
    es,
    zh,
};

export default Message;