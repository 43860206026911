import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import config from "../../config";

const MenuSale = (props) => {
    const intl = useIntl()
    return (
        <>
            <a href={`https://${config.domain}/${intl.locale}/auctions/future`} className="border border-black p-2 mr-2 bg-black-d transition duration-200 each-in-out">
                <span className="uppercase"><FormattedMessage id="menu.topbar.vente.auction"/></span>
            </a>
        </>
    )
};

export default MenuSale;