import { FormattedMessage } from "react-intl";
import React from "react";

const StepWindowLayout = props => (
    <div className="flex flex-row flex-auto flex-shrink-0 antialiased text-gray-800 mt-4 mb-4">
        <div className="hidden lg:block lg:w-1/3 shadow-lg bg-gray-400 p-10 rounded-bl-lg rounded-l-lg" >
            <h2 className="text-white text-3xl font-semibold"><FormattedMessage id={props.titleId} /></h2>
            <div className="text-white text-xl mt-4 font-semibold">
                {props.subtitleId && <FormattedMessage id={props.subtitleId} />}
            </div>
            <div className="text-white mt-2">
                {props.textId && <FormattedMessage id={props.textId} />}
            </div>
        </div>
        <div className="w-full lg:w-2/3 bg-white p-8 shadow-lg p-8 lg:rounded-tr-lg lg:rounded-br-lg step-window">
            {props.children}
        </div>
    </div>
);

export default StepWindowLayout;

