import React from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import AlertBar from "./AlertBar";
import {FormattedMessage} from "react-intl";




const Success = (props) => (
    <AlertBar open={props.successMessage !== ""} closeAction={props.resetMessage}>
        <div className="success text-white text-sm bg-green-700 bg-opacity-80 w-full flex flex-row justify-between">
            <div className="flex-grow">
                <span><FormattedMessage id={props.successMessage} /></span>
            </div>
            <div className="flex-1 text-right">
                <Button onClick={props.resetMessage}><span><FontAwesomeIcon color="white" icon={faTimes} size="lg"/> </span></Button>
            </div>
        </div>
    </AlertBar>
);


Success.propTypes = {
    resetMessage: PropTypes.func,
    successMessage: PropTypes.string,
};

export default Success;