import {AWAITING, KBIS, PENDING, REGISTER, VALID, VERIFIED} from "./structs/apiConstants";

const Passeport = ({userId, gender, firstName, lastName, birthDate, country, address, zipCode, city, addressDeliv, zipCodeDeliv, cityDeliv, countryDeliv, additionalAddressDeliv, stateDeliv, isAddressDelivSameAsAddress, phone, phoneConfirmed, professional, lang, creditCards, documents, idCheckStatus, company, siret, interVAT, newsletterDsi, newsletterPartner, additionalAddress, state}) => ({
    userId: userId || 0,
    gender: gender || "",
    firstName: firstName || '',
    lastName: lastName || '',
    birthDate: new Date(birthDate),
    country: country || '',
    address: address || '',
    zipCode: zipCode || '',
    city: city || '',
    addressDeliv: addressDeliv || '',
    zipCodeDeliv: zipCodeDeliv || '',
    cityDeliv: cityDeliv || '',
    countryDeliv: countryDeliv || '',
    additionalAddressDeliv: additionalAddressDeliv || '',
    stateDeliv: stateDeliv || '',
    isAddressDelivSameAsAddress: isAddressDelivSameAsAddress || false,
    phone: phone || '',
    phoneConfirmed: phoneConfirmed || false,
    professional: professional || false,
    lang: lang || '',
    creditCards: creditCards || [],
    documents: documents || [],
    idCheckStatus: idCheckStatus || "INVALID",
    company: company || '',
    siret: siret || '',
    interVAT: interVAT || '',
    newsletterDsi: newsletterDsi || false,
    newsletterPartner: newsletterPartner || false,
    additionalAddress: additionalAddress || "",
    state: state || "",
});

export const hasValidPersonalId =(pass) => pass.idCheckStatus === VALID;

export const isGoneThroughSumsub = (pass) => pass.idCheckStatus === VALID || pass.idCheckStatus === PENDING;

const hasNonRejectedDocsWithStatus = (pass, status="all") => {
    return (pass.documents.some(doc => {
        const condition = status === "all" ? doc.status === VERIFIED || doc.status === AWAITING : doc.status === status;
        return condition && (doc.type === REGISTER || doc.type === KBIS)
    }));
};

export const hasValidDocs = (pass) => {
    return !pass.professional || hasNonRejectedDocsWithStatus(pass, VERIFIED)
};

export const hasAwaitingDocs = (pass) => {
    return hasNonRejectedDocsWithStatus(pass, AWAITING)
};

export const hasNonRejectedDocs = (pass) => {
    return !pass.professional || hasNonRejectedDocsWithStatus(pass)
};

export const hasValidCardForDate = (pass, saleDate) => {
    return pass.creditCards.some(card => {
        return Date.parse(card.date) >= saleDate*1000;
    })
};

export default Passeport