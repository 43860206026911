import React from 'react';
import PropTypes from 'prop-types';
import DocumentSubmit from "../components/DocumentSubmit";
import {
    submitDocumentRequest,
    updateFormInputs,
    selectFormInputs,
    documentError
} from "../reducers/documentSlice";
import {useDispatch, useSelector} from "react-redux";

const DocumentSubmitContainer = (props) => {
    const dispatch = useDispatch();
    const fileFormInputs = useSelector(selectFormInputs);
    const saleId = props.saleId;
    function updateDocumentForm(e) {
        dispatch(updateFormInputs({
            name: e.target.name,
            value: e.target.value,
        }))
    }

    function updateFileForm(file) {
        dispatch(updateFormInputs({
            name: "idDoc",
            value: file,
        }))
    }

    function saveDocument() {
        if (props.onSaveAction) props.onSaveAction();
        dispatch(submitDocumentRequest({saleId,}))
    }

    return (
        <DocumentSubmit
            onChange={updateDocumentForm}
            onFileChange={updateFileForm}
            fileSizeError={() => dispatch(documentError("error.doc.size"))}
            saveDocument={saveDocument}
            fileFormInputs={fileFormInputs}
            setCanValidateStep={props.setCanValidateStep}
            canValidateStep={props.canValidateStep}
            isTunnel={props.isTunnel}
            saleId={saleId}
        />
    )
};

DocumentSubmitContainer.propTypes = {
    isTunnel: PropTypes.bool,
    onSaveAction: PropTypes.func,
};

export default DocumentSubmitContainer;