import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {selectError, selectSuccess, resetMessage} from "../reducers/authSlice";
import {selectPaymentError, resetMessage as resetPaymentMessage, selectPaymentSuccess} from "../reducers/paymentSlice";
import {selectError as selectRegistrationError, resetMessage as resetRegistrationMessage} from "../reducers/registrationSlice";
import {selectDocumentSuccess, selectDocumentError, resetMessage as resetDocumentMessage} from "../reducers/documentSlice";
import {selectError as selectCgvError, resetMessage as resetCgvMessage} from "../reducers/cgvSlice";
import Success from "../components/tools/Success";
import Error from "../components/tools/Error";

const MessageContainer = () => {
    const dispatch = useDispatch();
    const successMessage = useSelector(selectSuccess);
    const errorMessage = useSelector(selectError);
    const paymentError = useSelector(selectPaymentError);
    const registrationError = useSelector(selectRegistrationError);
    const documentSuccess = useSelector(selectDocumentSuccess);
    const paymentSuccess = useSelector(selectPaymentSuccess);
    const documentError = useSelector(selectDocumentError);
    const cgvError = useSelector(selectCgvError);

    const resetProperMessage = () => {
        let actionToDispatch;
        if (errorMessage) actionToDispatch = resetMessage;
        else if (paymentError) actionToDispatch = resetPaymentMessage;
        else if (registrationError) actionToDispatch = resetRegistrationMessage;
        else if (cgvError) actionToDispatch = resetCgvMessage;
        else actionToDispatch = resetDocumentMessage;
        dispatch(actionToDispatch());
    };


    return (
        <>
            {documentSuccess && <Success successMessage={documentSuccess} resetMessage={() => dispatch(resetDocumentMessage())}/>}
            {successMessage && <Success successMessage={successMessage} resetMessage={() => dispatch(resetMessage())}/>}
            {paymentSuccess && <Success successMessage={paymentSuccess} resetMessage={() => dispatch(resetPaymentMessage())}/>}
            {(errorMessage || paymentError || documentError || registrationError || cgvError) && <Error errorMessage={[errorMessage,paymentError,registrationError,documentError, cgvError].find(m => m !== null)} resetMessage={resetProperMessage}/>}
        </>
    )
};

export default MessageContainer;
