import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import PropTypes from "prop-types";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const AlertBar = props => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.closeAction();
    };

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={4500}
            onClose={handleClose}
            TransitionComponent={TransitionUp}
        >
            {props.children}
        </Snackbar>
)};

AlertBar.propTypes = {
    open: PropTypes.bool,
    closeAction: PropTypes.func.isRequired,
};

export default AlertBar;