import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import PhoneField from "./tools/PhoneField";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {selectFormInputs} from "../reducers/authSlice";

const ConfirmPhone = (props) => {

    const [canConfirm, setCanConfirm] = useState(false);
    const [canRequestNewCode, setCanRequestNewCode] = useState(true);
    useEffect(() => {
        props.requestConfirmationCodeSMS();
    }, []);

    const onCodeChange = e => {
        props.updateForm(e);
        if (e.target.value.length >= 4) {
            setCanConfirm(true);
            if (props.setCanValidateStep) props.setCanValidateStep(true);
        } else {
            setCanConfirm(false);
        }
    };

    const onCodeRequest = () => {
        if (canRequestNewCode) {
            props.requestConfirmationCodeSMS();
            setCanRequestNewCode(false);
            setTimeout(() => {
                setCanRequestNewCode(true)
            }, 35000)
        }
    };

    const onHandleError = (e) => {
        setCanRequestNewCode(!e);
    }

    return (
        <div className="modal-phone bg-white w-full">
            <div>
                <span className="best-title"><FormattedMessage id="common.confirm.phone"/></span>
                <div className="title-divider">
                    <span></span>
                </div>
            </div>
            <div className="clear"/>
            <div>
                <label className="block text-md font-bold mb-2"><FormattedMessage id="common.phone"/></label>
                <div className="inline-block pl-1 w-full">
                    <PhoneField
                        onChange={props.updateForm}
                        onInputErrorChanged={onHandleError}
                        phone={props.phone}
                    />
                </div>
                <br/>

                <div className="mt-4">
                    <div className="inline-block pl-1 w-full">
                        <TextField key="verificationCode" label="Code de verification" variant="filled" size="small" name="verificationCode" onChange={onCodeChange} defaultValue=""/>
                    </div>
                    <div className="inline-block w-full mt-2 text-left">
                        <Button disabled={!canRequestNewCode} onClick={onCodeRequest} size="small" color="primary"><FormattedMessage id="common.resend.confirmation"/></Button>
                    </div>
                </div>

                {!props.isTunnel &&
                <div className="mt-4">
                    <Button disabled={!canConfirm} onClick={props.confirmPhoneRequest} variant="contained" color="primary">
                        <FormattedMessage id="common.confirm.phone"/>
                    </Button>
                </div>}
            </div>
        </div>
    )
};

ConfirmPhone.propTypes = {
    requestConfirmationCodeSMS: PropTypes.func,
    savePhone: PropTypes.func,
    confirmPhoneRequest: PropTypes.func,
    setCanValidateStep: PropTypes.func,
    updateForm: PropTypes.func,
    isTunnel: PropTypes.bool,
};

export default ConfirmPhone;