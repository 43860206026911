import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import SaleDate from "./tools/SaleDate";
import PropTypes from "prop-types";
import {registrationType, saleRestrictionsType, saleType} from "../structs/types";
import {RESTRICTED_LIMIT} from "../structs/apiConstants";

const SaleTunnelWrapper = (props) => {
    let saleType = "label.sale.type.cp"
    switch(props.saleInfo.cpType) {
        case "STOCKER":
            saleType = "label.sale.type.stocker"
            break;
        case "COLLECTIVITY":
            saleType = "label.sale.type.collectivity"
            break;
    }

    return(
        <div className="flex flex-row flex-auto flex-shrink-0 antialiased text-gray-800 mt-4 mb-4">
            <div className="hidden lg:block lg:w-1/3 shadow-lg bg-gray-400 p-10 rounded-bl-lg rounded-l-lg" >
                <h2 className="text-white text-3xl font-semibold"><FormattedMessage id={props.titleLabel}/></h2>
                <div className="text-white text-xl mt-2 font-semibold">
                    {props.saleInfo.cpName}<br/>
                    <FormattedMessage id={saleType}/>
                </div>
                <div className="text-white text-xl mt-2 font-semibold">
                    {props.saleInfo.title}
                    <br/>
                    <SaleDate date={props.saleInfo.date}/>
                </div>
                {props.restrictions && (
                        ((props.registration && (props.restrictions.biddingLimit.defaultValue > 0 || props.registration.filtered === "RESTRICTED" || (props.restrictions.filtering === "LIMITED" && props.registration.filtered !== "UNFILTERED"))) && <p className="xs:hidden text-white text-md mt-5">
                            <div className="font-semibold"><FormattedMessage id="label.plafond"/> :</div>
                            <FormattedMessage id="label.plafond.montant"/> <FormattedNumber value={props.registration.filtered === "RESTRICTED" ? RESTRICTED_LIMIT : props.restrictions.filtering === "LIMITED" && props.registration.filtered !== "UNFILTERED" ? props.restrictions.filteredLimit : props.restrictions.biddingLimit.defaultValue} style="currency" currency={props.saleInfo.currency}/>. <FormattedMessage id="label.plafond.explication"/>
                        </p>) ||
                        (props.restrictions.deposit > 0 && <p className="xs:hidden text-white text-md mt-5">
                            <div className="font-semibold"><FormattedMessage id="label.caution"/> :</div>
                            <FormattedMessage id="label.caution.montant"/> <FormattedNumber value={props.restrictions.deposit} style="currency" currency={props.saleInfo.currency}/>. <FormattedMessage id="label.caution.explication"/>
                        </p>) ||
                        (props.saleInfo.cpType !== "STOCKER" && props.saleInfo.cpType !== "COLLECTIVITY" && <p className="xs:hidden text-white text-xs mt-5">
                            <FormattedMessage id="label.rappel"/> :<br/>
                            <FormattedMessage id="label.rappel.explication"/>
                        </p>)
                    )
                }

            </div>
            <div className="w-full lg:w-2/3 bg-white py-8 shadow-lg lg:rounded-tr-lg lg:rounded-br-lg">
                <h2 className="2xl:hidden xl:hidden lg:hidden text-center"><FormattedMessage id={props.titleLabel}/></h2>
                <div className="2xl:hidden xl:hidden lg:hidden text-xl font-semibold text-center">{props.saleInfo.cpName}</div>
                <div className="2xl:hidden xl:hidden lg:hidden text-xl mb-2 font-semibold text-center"><SaleDate date={props.saleInfo.date}/></div>

                {props.children}

            </div>
        </div>
    );
};

SaleTunnelWrapper.propTypes = {
    restrictions: saleRestrictionsType,
    saleInfo: saleType,
    titleLabel: PropTypes.string,
    registration: registrationType,
    userCountry: PropTypes.string,
};

export default SaleTunnelWrapper;
