import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import Cgu from "../Cgu";
import SelectCountry from "../tools/SelectCountry";
import {TextField} from "@material-ui/core";
import DatePickers from "../tools/DatePickers";
import {checkAndSetCanValidateStep} from "../../HelperFunctions";
import PhoneField from "../tools/PhoneField";

const basicMandatoryFields = ['cgu', 'lastName', 'firstName', 'professional', 'address', 'country', 'zipCode', 'city', 'phone', 'birthDate'];
const mandatoryFields = [...basicMandatoryFields];

const ProfileNameAdress = (props) => {
    const intl = useIntl()
    const [inputs, setInputs] = useState({});
    const [isPro, setIsPro] = useState(false);
    const [newCountry, setNewCountry] = useState("");
    const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);

    useEffect(() => {
        props.rawUpdateInput("lang", intl.locale);
        props.rawUpdateInput("country", '75');
        setInputs({country:'75'})
    }, []);

    const onChange = e => {
        const newInputs = {...inputs, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked :
                e.target.value.trim().replaceAll("’", "'")}; //Handle special iPhone character
        switch (e.target.name) {
            case "professional":
                while (mandatoryFields.length > basicMandatoryFields.length) {
                    mandatoryFields.pop();
                }
                if (e.target.value === "Professionnal") {
                    setIsPro(true);
                    mandatoryFields.push("company", "siret");
                    props.rawUpdateInput(e.target.name, true);
                } else {
                    setIsPro(false);
                    props.rawUpdateInput(e.target.name, false);
                }
                break;
            case "country":
                setNewCountry(e.target.value);
                props.updateForm(e);
                break;
            default:
                props.updateForm(e);
        }
        setInputs(newInputs);
        checkAndSetCanValidateStep(newInputs, mandatoryFields, props.canValidateStep, props.setCanValidateStep, setHasInvalidCharacters)
    };

    return (
        <>
            <h2 className="mt-2 text-xl dark:text-gray-300 font-extrabold capitalize">
                <FormattedMessage id="label.mon.compte"/>
            </h2>
            <div>
                <div className="inline-block mt-2 w-1/2 pr-1">
                    <label className="block text-sm font-bold mb-2">
                        <input type="radio" name="professional" id="particulier" value="Particulier" className="mr-2" onChange={onChange} /><FormattedMessage id="common.particulier"/>
                    </label>
                </div>
                <div className="inline-block mt-2 -mx-1 pl-1 w-1/2">
                    <label className="block text-sm font-bold mb-2">
                        <input type="radio" name="professional" id="Professionnel" value="Professionnal" className="mr-2" onChange={onChange} /><FormattedMessage id="common.professionnel"/>
                    </label>
                </div>

                {isPro && <span className="text-sm inline-block mt-2 w-full pr-1"><FormattedMessage id="label.leader.info"/></span>
                }
                <div className="inline-block mt-2 w-full sm:w-1/2 sm:pr-1">
                    <TextField key="firstName" label={<FormattedMessage id="label.firstname"/>} variant="filled" size="small" name="firstName" onChange={onChange} defaultValue=""/>
                </div>

                <div className="inline-block mt-2 w-full sm:pl-1 sm:w-1/2">
                    <TextField key="lastName" label={<FormattedMessage id="label.lastname"/>} variant="filled" size="small" name="lastName" onChange={onChange} defaultValue=""/>
                </div>

                <div className="inline-block mt-2 w-full sm:w-1/2 sm:pr-1">
                    <DatePickers disableFuture name="birthDate" onChange={onChange} />
                </div>
                <br/>

                {isPro &&
                    <span className="text-sm inline-block mt-2 w-full pr-1">
                        <FormattedMessage id="label.company.info"/>
                    </span>}

                    <div className="inline-block mt-2 w-full sm:w-1/2 sm:pr-1">
                        <SelectCountry onChange={onChange} defaultValue="75"/>
                    </div>
                    <br/>

                    {isPro && <>
                    <div className="inline-block mt-2 w-full">
                        <TextField key="company" label={<FormattedMessage id="label.company"/>} variant="filled" size="small" name="company" onChange={onChange} defaultValue=""/>
                    </div>

                    <div className="inline-block mt-2 w-full">
                        <TextField
                            key="siret"
                            label={<FormattedMessage id="label.company.siret"/>}
                            variant="filled" size="small" name="siret"
                            onChange={onChange} defaultValue=""
                            error={inputs["siret"] && (inputs["siret"].length !== 14 || !inputs["siret"].match(/^[0-9]+$/))}
                        />
                    </div>

                    <div className="inline-block mt-2 w-full">
                        <TextField
                            key="interVAT"
                            label={<FormattedMessage id="label.company.interVAT"/>}
                            variant="filled" size="small" name="interVAT"
                            onChange={onChange} defaultValue=""
                        />
                    </div>
                </>
                }

                <div className="inline-block mt-2 w-full">
                    <TextField key="address" label={<FormattedMessage id="label.address"/>} variant="filled" size="small" name="address" onChange={onChange} defaultValue=""/>
                </div>
                <div className={`inline-block mt-2 w-full ${newCountry !== '75' ? "sm:w-1/2 sm:pr-1" : ""}`}>
                    <TextField key="additionalAddress" label={<FormattedMessage id="label.addresscomplement"/>} variant="filled" size="small" name="additionalAddress" onChange={onChange} defaultValue=""/>
                </div>
                {newCountry !== '75' &&
                <div className="inline-block mt-2 w-full sm:w-1/2 sm:pr-1">
                    <TextField key="state" label={<FormattedMessage id="label.addressstate"/>} variant="filled"
                               size="small" name="state" onChange={onChange} defaultValue=""/>
                </div>
                }
                <div className="inline-block mt-2 w-full sm:w-1/2 sm:pr-1">
                    <TextField key="zipCode" label={<FormattedMessage id="label.zipcode"/>} variant="filled" size="small" name="zipCode" onChange={onChange} defaultValue=""/>
                </div>
                <div className="inline-block mt-2 w-full sm:pl-1 sm:w-1/2">
                    <TextField key="city" label={<FormattedMessage id="label.city"/>} variant="filled" size="small" name="city" onChange={onChange} defaultValue=""/>
                </div>
                <div className="clear"/>
                <div className="inline-block mt-2 w-full">
                    <span className="text-sm"><FormattedMessage id="label.telephone.error" /></span>
                </div>
                <div className="inline-block mt-2 w-full sm:pr-1 sm:w-1/2">
                    <PhoneField
                        onChange={onChange}
                        onInputErrorChanged={(e) => {
                            //No need to block anything here as we are on register page
                            //Unlike modal where we disable the resend button
                        }}
                    />
                </div>
                <div className="inline-block mt-2 w-full">
                    <label className="block text-sm font-bold mb-2 mt-4"><FormattedMessage id="common.notification"/></label>
                    <Cgu onChange={onChange} showCGU/>
                </div>
                {
                    !props.isTunnel &&
                    <>
                        <button onClick={props.saveProfile} className="bg-blue-700 text-white px-6 py-2 rounded font-medium hover:bg-blue-800 transition duration-200 each-in-out mt-4" >
                            <FormattedMessage id="common.save.profil"/>
                        </button>

                        <button className="bg-white border text-gray-700 px-6 py-2 rounded hover:bg-gray-200 transition duration-200 each-in-out ml-2 mt-4">
                            <FormattedMessage id="label.cancel"/>
                        </button>
                    </>

                }
            </div>
            <div className="mt-2 bold text-sm text-red-500" hidden={!hasInvalidCharacters}>
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                {' '}
                <FormattedMessage id="use.latin.characters"/>
            </div>
        </>
    )
};

ProfileNameAdress.propTypes = {
    updateForm: PropTypes.func,
    rawUpdateInput: PropTypes.func,
    canValidateStep: PropTypes.bool,
    setCanValidateStep: PropTypes.func,
};

export default ProfileNameAdress;