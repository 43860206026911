import {FormattedMessage} from "react-intl";
import React from "react";
import {cardType} from "../../structs/types";
import PropTypes from "prop-types";

const WithSelectedCard = (props) => {

    const checkCvv = cvvInput => {
        if(!cvvInput) {
            if (props.canSubmit) props.setCanSubmit(false);
            return "error.cvv.required";
        }
        if ((cvvInput.length !== 3 && cvvInput.length !== 4) || !Number.isInteger(Number(cvvInput))) {
            if (props.canSubmit) props.setCanSubmit(false);
            return "error.cvv.invalid";
        }
        props.setCanSubmit(true)
    }

    const selectedCardField = (translationId, fieldValue) => (
        <div className="flex flex-col">
            <FormattedMessage id={translationId} />
            <div className="flex w-full bg-gray-50 rounded border border-gray-400 p-2 text-gray-400">
                {fieldValue}
            </div>
        </div>
    )

    return <div className="flex flex-col gap-2 py-2 pr-2">
        {selectedCardField("label.cardNumber", props.selectedCard.description)}
        {selectedCardField("label.expire.on", props.selectedCard.date.substring(0, 10))}
        <div className="flex flex-col">
            CVC
            <input className={`p-2 rounded w-1/2 md:w-1/5 border ${checkCvv(props.cvv) ? "border-red-300" : 'border-gray-400'} bg-gray-50 focus:ring-0 focus:outline-none`} name="cvv" onChange={e => props.setCvv(e.target.value)} defaultValue={props.cvv}/>
        </div>
    </div>
}

WithSelectedCard.propTypes = {
    selectedCard: cardType.isRequired,
    setCvv: PropTypes.func.isRequired,
    cvv: PropTypes.string.isRequired,
    setCanSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
}
export default WithSelectedCard;