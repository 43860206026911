import React from "react";
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";

import {FormattedMessage, useIntl} from "react-intl";
import config from "../../config";

const countries = {
    fr: {...require('../../translations/country/fr')},
    en: {...require('../../translations/country/en')},
    it: {...require('../../translations/country/it')},
    de: {...require('../../translations/country/de')},
    es: {...require('../../translations/country/es')},
    zh: {...require('../../translations/country/zh')},
};

const SelectCountry = (props) => {
    const intl = useIntl();
    const options = Object.keys(countries[intl.locale]).map(id => (
        {
            id,
            country: countries[intl.locale][id],
        }
    ));

    const onChangeCountry = (event, value) => {
        const e = {target: {}};
        e.target.name = props.isCountryDeliv ? "countryDeliv" : "country";
        e.target.value = value ? value.id : "";
        props.onChange(e)
    };

    return (
            <Autocomplete
                InputLabelProps={{
                    shrink: true,
                }}
                key={`autocomplete.${props.isCountryDeliv ? "countryDeliv" : "country"}.${props.defaultValue}`}
                defaultValue={options[parseInt(props.defaultValue)-1] || ""}
                getOptionLabel={(option) => option.country}
                name="country"
                onChange={onChangeCountry}
                options={options}
                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="label.select.pays" />} variant="outlined" />}
                renderOption={(option) => (
                    <React.Fragment>
                        <span key={option.id}>{option.country}</span>
                    </React.Fragment>
                )}
            />
    )
};

SelectCountry.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    isCountryDeliv: PropTypes.bool,
};

export default SelectCountry;