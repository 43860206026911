import {FormattedMessage} from "react-intl";
import {TextField} from "@material-ui/core";
import IconTick from "../tools/IconTick";
import React from "react";

const ProfileField = (props) => {
    return (
        <>
            <div className={`inline-block my-2 ${props.label ? "w-full" : "w-0"} sm:w-2/6 align-top text-gray-500`}><label>{props.label && <FormattedMessage id={props.label}/>}</label></div>
            <div className="inline-block my-2 w-5/6 sm:w-3/6">
                <TextField label={<FormattedMessage id={props.inputLabel}/>} variant="filled" size="small" name={props.name} onChange={props.onChange} defaultValue={props.value}/>
            </div>
            <div className="inline-block pl-1 w-1/6 text-center align-bottom h-8">
                <IconTick isValid={props.optional || props.value !== ""} />
            </div>
        </>
    )
};

export default ProfileField;