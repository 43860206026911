import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
    },
}));

const SelectLang = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} noValidate>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
                <FormattedMessage id="common.langue"/>
            </InputLabel>
            <NativeSelect
                InputLabelProps={{
                    shrink: true,
                }}
                name="lang"
                onChange={props.onChange}
            >
                <FormattedMessage id="label.select.langue" key="0">
                    {(message) => <option name="options" disabled>{message}</option>}
                </FormattedMessage>

                <FormattedMessage id='label.language.fr'>
                    {(message) => <option selected={props.lang==="fr"} value='fr'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.language.en'>
                    {(message) => <option selected={props.lang==="en"}  value='en'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.language.de'>
                    {(message) => <option selected={props.lang==="de"}  value='de'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.language.es'>
                    {(message) => <option selected={props.lang==="es"}  value='es'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.language.it'>
                    {(message) => <option selected={props.lang==="it"}  value='it'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.language.zh'>
                    {(message) => <option selected={props.lang==="zh"}  value='zh'>{message}</option>}
                </FormattedMessage>
            </NativeSelect>
        </FormControl>
    )
};


export default SelectLang;