import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import config from "../../config";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const MenuAccount = (props) => {
    const intl = useIntl()
    const domain = `https://${config.domain}/${intl.locale}`
    return (
        <>
            <div className="relative inline-block text-right dropdown">
                    <span className="rounded-md shadow-sm">
                        <button className="btn ghost" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items-117">
                            <span><FormattedMessage id="mypass.menu.mon.compte"/></span>
                        </button>
                    </span>
                <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                    <div className="absolute right-0 w-56 pt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                        <div className="py-1">
                            <Link to="/profile/account"
                               className="text-gray-700 flex justify-between w-full pt-2 px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.information.profil"/>
                            </Link>

                            <a href={`${domain}/account/registrations`} tabIndex="1"
                               className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.menu.mes.inscriptions"/></a>

                            <a href={`${domain}/account/bids`} tabIndex="2"
                               className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.menu.encheres"/></a>

                            <a href={`${domain}/account/absentee-bids`} tabIndex="2"
                               className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.menu.ordres"/></a>

                            <a href={`${domain}/account/favorites`} tabIndex="2"
                               className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.menu.favori"/></a>

                            <a href={`${domain}/account/alerts`} tabIndex="2"
                               className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                               role="menuitem"><FormattedMessage id="mypass.menu.alerte"/></a>
                        </div>
                        <div className="py-2 text-center">
                            <button onClick={props.signOut} tabIndex="3" className="btn ghost mt-2"
                               role="menuitem"><FormattedMessage id="mypass.menu.deconnexion"/></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

MenuAccount.propTypes = {
    signOut: PropTypes.func,
};
export default MenuAccount;