import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from '@aws-amplify/ui-components';
import Passeport from "../Passeport";

const initialState = {
    loading: false,
    error: null,
    success: "",
    formInputs: {},
    saleId: null
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        submitDocumentRequest: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = "";
            state.saleId = action.payload.saleId;
        },
        submitDocumentSuccess: state => {
            state.loading = false;
            state.error = null;
            state.success = "success.document.submit";
        },
        updateFormInputs: (state, action) => {
            state.formInputs[action.payload.name] = action.payload.value;
        },
        resetMessage: state => {
            state.error = null;
            state.success = "";
        },
        documentError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.success = "";
        }
    },
});

export const {
    submitDocumentRequest,
    submitDocumentSuccess,
    updateFormInputs,
    resetMessage,
    documentError,
} = documentSlice.actions;

export const selectFormInputs = state => state.document.formInputs;
export const selectDocumentLoading = state => state.document.loading;
export const selectDocumentError = state => state.document.error;
export const selectDocumentSuccess = state => state.document.success;
export const selectSaleId = state => state.document.saleId

export default documentSlice.reducer;