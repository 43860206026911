import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {confirmForgotPasswordRequest} from "../reducers/authSlice";
import HeaderLight from "../components/global/HeaderLight";
import ResetPassword from "../components/ResetPassword";
import {getTokenFromQueryParam, isTokenStillValid} from "../HelperFunctions";
import {FormattedMessage} from "react-intl";
import {push} from "connected-react-router";

const ResetPasswordContainer = (props) => {
    const dispatch = useDispatch();
    const token = getTokenFromQueryParam();
    if (!isTokenStillValid(token)) {
        dispatch(push("/forgot-password"));
    }
    return (
        <>
            <HeaderLight/>
            <ResetPassword
                onChange={props.updateForm}
                confirmForgotPasswordRequest={() => dispatch(confirmForgotPasswordRequest())}
            />
        </>
    )
};

ResetPasswordContainer.propTypes = {
    updateForm: PropTypes.func,
};

export default ResetPasswordContainer;