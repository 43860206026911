import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    dataPerSale: {},
};

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        getRegistrationForSaleRequest: state => {
            state.loading = true;
            state.error = null;
        },
        getRegistrationForSaleSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.dataPerSale[action.payload.id] = {
                restrictions: action.payload.restrictions,
                registration: action.payload.registration,
                sale: action.payload.sale,
            }
        },
        createRegistrationForSaleRequest: state => {
            state.loading = true;
            state.error = null;
        },
        createRegistrationForSaleSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            if (action.payload) state.dataPerSale[action.payload.sale.id].registration = action.payload;
        },
        resetMessage: state => {
            state.error = null;
        },
        registrationError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const selectLoading = state => state.registration.loading;
export const selectError = state => state.registration.error;
export const selectRestrictionsForSale = (state, saleId) => state.registration.dataPerSale[saleId] ? state.registration.dataPerSale[saleId].restrictions : null;
export const selectRegistrationForSale = (state, saleId) => state.registration.dataPerSale[saleId] ? state.registration.dataPerSale[saleId].registration : null;
export const selectInfoForSale = (state, saleId) => state.registration.dataPerSale[saleId] ? state.registration.dataPerSale[saleId].sale : null;

export const {
    getRegistrationForSaleRequest,
    getRegistrationForSaleSuccess,
    createRegistrationForSaleRequest,
    createRegistrationForSaleSuccess,
    resetMessage,
    registrationError,
} = registrationSlice.actions;

export default registrationSlice.reducer;