import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    activeStep: 0,
    canValidateStep: false,
    stepSuccess: false,
    steps: [],
};

export const tunnelSlice = createSlice({
    name: 'tunnel',
    initialState,
    reducers: {
        resetTunnel: state => {
            Object.assign(state, initialState);
        },
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setCanValidateStep: (state, action) => {
            state.canValidateStep = action.payload;
        },
        stepSucceeded: (state, action) => {
            state.stepSuccess = true;
        },
        setActiveStep: (state, action) => {
            state.stepSuccess = false;
            state.activeStep = action.payload;
        },
        nextActiveStep: state => {
            state.stepSuccess = false;
            state.activeStep++;
        },
    }
});

export const selectStepSuccess = state => state.tunnel.stepSuccess;
export const selectActiveStep = state => state.tunnel.activeStep;
export const selectCanValidateStep = state => state.tunnel.canValidateStep;
export const selectSteps = state => state.tunnel.steps;

export const {
    resetTunnel,
    setActiveStep,
    nextActiveStep,
    stepSucceeded,
    setSteps,
    setCanValidateStep,
} = tunnelSlice.actions;

export default tunnelSlice.reducer