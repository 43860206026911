import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from "./reducers/authSlice";
import documentReducer from "./reducers/documentSlice";
import registrationReducer from "./reducers/registrationSlice";
import tunnelReducer from "./reducers/tunnelSlice";
import navigationReducer from "./reducers/navigationSlice";
import paymentReducer from "./reducers/paymentSlice";
import cgvReducer from "./reducers/cgvSlice"
import watchAuthSaga from "./sagas/authSagas";
import documentSagas from "./sagas/documentSagas";
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import "./config";
import watchRegistrationSaga from "./sagas/registrationSagas";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import watchPaymentSaga from "./sagas/paymentSagas"; // defaults to localStorage for web
import watchCgvSaga from "./sagas/cgvSagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
export const history = createBrowserHistory('');

// initial location and redir uri needs persistance for social login
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['navigation'],
};

const reducers = combineReducers({
    auth: authReducer,
    document: documentReducer,
    registration: registrationReducer,
    tunnel: tunnelReducer,
    navigation: navigationReducer,
    payment: paymentReducer,
    cgv: cgvReducer,
    router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }), sagaMiddleware, routerMiddleware(history)],
});

sagaMiddleware.run(watchAuthSaga);
sagaMiddleware.run(documentSagas);
sagaMiddleware.run(watchPaymentSaga)
sagaMiddleware.run(watchRegistrationSaga);
sagaMiddleware.run(watchCgvSaga);