import {shape, number, string, bool} from 'prop-types';

export const saleRestrictionsType = shape({
    validPhone: bool,
    validPaymentCard: bool,
    "3DSCard": bool,
    validDocKyc: string,
    deposit: number,
    hasIngenico: bool,
    biddingLimit: shape({
        defaultValue: number,
        coverage: number,
    }),
    depositStartDate: number,
});

export const registrationStatusPropertiesType = shape({
    validPhone: bool,
    validIdDocument: bool,
    validPaymentCard: bool,
    validSecurityDeposit: bool,
    valid3DSCard: bool,
    blacklisted: bool,
    countryLock: bool,
    needsVerification: bool,
});

export const registrationSaleType= shape({
    id: number,
    cpId: number,
    date: number,
    address: number,
    title: string,
    timeZone: string,
    type: string,
});

export const registrationType = shape({
    id: number,
    sale: registrationSaleType,
    status: number,
    statusProperties: registrationStatusPropertiesType,
    maxAmount: number,
    remainingLimit: number,
    hasRemainingLimit: bool,
    hasIngenico: bool,
    validationDate: number,
    filtered: string,
});

export const saleType = shape({
    id: number,
    title: string,
    description: string,
    originalDescription: string,
    date: number,
    timeZone: string,
    type: string,
    status: string,
    address: string,
    cpId: number,
    cpName: string,
    cpPhone: string,
    cpMail: string,
    cpAddress: string,
    cpType: string,
    coverPhoto: string,
    conditions: string,
    originalConditions: string,
    currency: string,
    maxInscriptionDate: string,
});

export const cardType = shape({
    alias: string,
    description: string,
    date: string
})