import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {createPaymentRequest, initHostedTokenization, paymentError, selectPaymentTokenization, resetPayment} from "../../reducers/paymentSlice";
import {useDispatch} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {DepositType, NewCardType, SecurityDepositType} from "../../structs/apiConstants";
import Button from "@material-ui/core/Button";
import WithSelectedCard from "./WithSelectedCard";
import {cardType} from "../../structs/types";


const IngenicoIframeForm = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    let tokenizer;
    const [cvv, setCvv] = useState("");
    const [canSubmit, setCanSubmit] = useState(true);

    const collectDeviceInformation = function () {
        return {
            "timezoneOffsetUtcMinutes": `${new Date().getTimezoneOffset()}`,
            "locale": navigator.language,
            "browserData": {
                "javaScriptEnabled": true,
                "javaEnabled": navigator.javaEnabled(),
                "colorDepth": window.screen.colorDepth,
                "screenHeight": `${window.screen.height}`,
                "screenWidth": `${window.screen.width}`,
            }
        };
    }

    useEffect(() => () => dispatch(resetPayment()), [])

    useEffect(() => {
        if (props.selectedCard) {
            return
        }
        const tokenizerScript = document.createElement('script');
        tokenizerScript.src = "https://payment.direct.worldline-solutions.com/hostedtokenization/js/client/tokenizer.min.js";
        tokenizerScript.async = true;
        tokenizerScript.onload = () => {
            dispatch(initHostedTokenization({pspid: props.pspid, locale: intl.locale}));
        }
        document.body.appendChild(tokenizerScript);
        return () => {
            document.body.removeChild(tokenizerScript);
        }
    }, [props.selectedCard]);

    useEffect(() => {
        if (props.tokenization) {
            tokenizer = new window.Tokenizer(`${props.tokenization.hostedTokenizationUrl}`, 'div-hosted-tokenization', {
                hideCardholderName: false,
                hideTokenFields: true,
                storePermanently: true,
            });
            tokenizer.initialize().then(() => {
                console.log("tokenizer initiated");
                setCanSubmit(true);
                // Do work after initialization, if any
            }).catch(reason => {
                console.error(reason)
                // Handle iFrame load error
            });
        }
    }, [props.tokenization])

    const submitForm = async () => {
        const dispatchData = {
            pspid: props.pspid,
            type: props.type,
            amount: props.amount,
            saleId: props.saleId,
            isTunnel: props.isTunnel,
            selectedCard: props.selectedCard?.alias,
            cvv: cvv,
        };
        const deviceData = collectDeviceInformation();
        dispatchData.timezoneOffsetUtcMinutes = deviceData.timezoneOffsetUtcMinutes;
        dispatchData.locale = deviceData.locale;
        dispatchData.browserData = deviceData.browserData;
        if (!props.selectedCard) {
            const result = await tokenizer.submitTokenization()
            if (result.success) {
                dispatchData.hostedTokenizationId = result.hostedTokenizationId;
            } else {
                dispatch(paymentError(result.error.message));
                return
            }
        }
        dispatch(createPaymentRequest(dispatchData));
    }

    const handleKeypress = async (e) => {
        if (canSubmit && e.code === "Enter") {
            await submitForm();
        }
    };

    return (
        <>
            <div className="flex flex-col w-full overflow-y-auto" style={{height: "80vh"}}>
                <div className="text-sm md:text-base bg-gray-200 p-3 md:p-6">
                    {props.isTunnel ?
                        <><FormattedMessage id="label.should.confirm.credit.card.explain"/></> :
                        props.type === NewCardType && <><FormattedMessage id="label.store.alias.form"/></>
                    }
                    {props.type === SecurityDepositType && <><FormattedMessage id="label.security.deposit.form" values={{amount: props.amount}}/></>}
                    {props.type === DepositType && <><FormattedMessage id="label.deposit.form" values={{amount: props.amount}}/></>}
                    <br/>
                    <span className="bold"><FormattedMessage id="label.transaction.not.debited"/></span>
                </div>

                <div className="flex-auto flex w-full mt-4">
                    <div className="w-full lg:w-8/12 h-full pr-2" onKeyUp={handleKeypress}>
                        {!props.selectedCard ?
                            <div id="div-hosted-tokenization" className="w-full"></div>
                            :
                            <WithSelectedCard
                                selectedCard={props.selectedCard}
                                setCvv={setCvv}
                                cvv={cvv}
                                canSubmit={canSubmit}
                                setCanSubmit={setCanSubmit}
                            />
                        }
                        <Button size="small" disabled={!canSubmit} color="primary" variant="contained" onClick={submitForm}>
                            <FormattedMessage id="common.confirm"/>
                        </Button>
                    </div>
                    <div className="hidden lg:block lg:w-4/12 bg-white shadow-md p-8 rounded-lg text-center max-h-96">
                        <div className="text-lg uppercase bold"><FormattedMessage id="label.norme.3d"/></div>
                        <div className="bg-help-ingenico-cb bg-no-repeat bg-center bg-cover max-w-sm md:max-w-lg h-24 my-4"/>
                        <div className="text-xs"><FormattedMessage id="label.norme.3d.texte"/></div>
                        <div className="bg-help-ingenico bg-no-repeat bg-center bg-contain max-w-sm md:max-w-lg h-12 mt-4"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IngenicoIframeForm;

IngenicoIframeForm.propTypes = {
    selectedCard: cardType,
    pspid: PropTypes.string,
    type: PropTypes.number,
    amount:PropTypes.number,
    saleId: PropTypes.number,
    isTunnel: PropTypes.bool,
    tokenization: PropTypes.shape({
        hostedTokenizationUrl: PropTypes.string,
        hostedTokenizationId: PropTypes.string,
    }),
};