import eu from "../../asset/icon/icon-eu.svg";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import React from "react";

const IdRegionSelect = (props) => (
    <div className="flex flex-col h-60 w-96">
        <div className="flex flex-1 items-center justify-center text-xl"><FormattedMessage id="label.select.citizenship"/>:</div>
        <div className="flex-1 flex">
            <button onClick={() => props.setLevel("id-doc-only")} className="flex-1 bg-white rounded-lg shadow-md p-4 my-2 text-center mx-auto min-h-32">
                <img src={eu} alt="EU" className="h-5/6"/>
                <div className="pt-2 bold"><FormattedMessage id="label.live.in.eu"/></div>
            </button>

            <div className="mx-2" />
            <button onClick={() => props.setLevel("basic-kyc-level")} className="flex-1 bg-white rounded-lg shadow-md p-4 my-2 text-center min-h-32">
                <FontAwesomeIcon className="h-5/6" size="6x" icon={faGlobe}/>
                <div className="pt-2 bold"><FormattedMessage id="label.live.out.eu"/></div>
            </button>
        </div>
    </div>
);

export default IdRegionSelect;