import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import SelectLang from "../tools/SelectLang";
import Cgu from "../Cgu";

const ProfilePreference = (props) => {
    return (
        <>
            <h2 className="mt-2 text-xl dark:text-gray-300 font-extrabold capitalize">
                <FormattedMessage id="label.preference"/>
            </h2>
            <div>
                <div className="inline-block my-2 w-2/6 pr-1 text-gray-500">
                    <label><FormattedMessage id="label.langue"/></label>
                </div>
                <div className="inline-block my-2 w-3/6">
                    <SelectLang onChange={props.onChange} lang={props.currentUserProfile.lang}/>
                </div>
                <div className="inline-block mt-2 pl-1 w-1/6 text-center"></div>

                <div className="inline-block mt-5 pl-1 w-full">
                    <Cgu
                        onChange={props.onChange}
                        drouotNewsChecked={props.currentUserProfile.newsletterDsi}
                        partnerNewsChecked={props.currentUserProfile.newsletterPartner}
                    />
                </div>
            </div>
        </>
    )
};

ProfilePreference.propTypes = {
    onChange: PropTypes.func,
    isValidUser: PropTypes.bool,
    currentUserProfile: PropTypes.object,
};


export default ProfilePreference;