import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {confirmSignUpRequest, resendConfirmationMail} from "../reducers/authSlice";
import ConfirmSignUp from "../components/ConfirmSignUp";

const ConfirmSignUpContainer = (props) => {
    const dispatch = useDispatch();
    return (
        <ConfirmSignUp
            updateForm={props.updateForm}
            canValidateStep={props.canValidateStep}
            setCanValidateStep={props.setCanValidateStep}
            resendConfirmationMail={() => dispatch(resendConfirmationMail())}
            confirmSignUp={() => dispatch(confirmSignUpRequest())}
        />    )
};

ConfirmSignUpContainer.propTypes = {
    updateForm: PropTypes.func,
    canValidateStep: PropTypes.bool,
    setCanValidateStep: PropTypes.func,
};


export default ConfirmSignUpContainer;