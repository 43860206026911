import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    confirmForgotPasswordRequest,
    forgotPasswordRequest,
    resetForgotSent,
    resetFormInputs
} from "../reducers/authSlice";
import ForgotPassword from "../components/ForgotPassword";
import HeaderLight from "../components/global/HeaderLight";

const ForgotPasswordContainer = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetFormInputs())
    }, []);
    return (
        <>
            <HeaderLight/>
            <ForgotPassword
                onChange={props.updateForm}
                forgotPasswordRequest={(lang) => dispatch(forgotPasswordRequest({lang}))}
                confirmForgotPasswordRequest={() => dispatch(confirmForgotPasswordRequest())}
            />
        </>
    )
};

ForgotPasswordContainer.propTypes = {
    updateForm: PropTypes.func,
};

export default ForgotPasswordContainer;