import React from "react";
import Input from "./tools/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
const ConfirmSignUp = (props) => {

    const onChangeCode = (e) => {
        props.updateForm(e);
        if (e.target.value.length >= 3) {
            if (!props.canValidateStep) props.setCanValidateStep(true);
        } else if (props.canValidateStep) {
            props.setCanValidateStep(false);
        }
    };

    return (
        <div>
            <h2 className="text-2xl font-bold text-left"><FormattedMessage id="common.code.verification"/></h2>
            <div className="mt-4">
                <div className="flex justify-between">
                    <label className="block text-sm font-bold mb-2"><FormattedMessage id="common.code.verification"/></label>
                    <button onClick={props.resendConfirmationMail} className="text-xs text-gray-500"><FormattedMessage id="common.resend.confirmation"/></button>
                </div>
                <Input
                    name="verificationCode"
                    placeholder="code de verification"
                    onChange={onChangeCode}
                />
            </div>

        </div>
    )
};

export default ConfirmSignUp;