import {call, put, select, takeLatest} from 'redux-saga/effects';
import {dispatchErrorAccordingToStatus, getOrPostToAuthenticatedRoute} from "./helper";
import {selectUser} from "../reducers/authSlice";
import {
    cgvError,
    createCgvForSaleRequest,
    createCgvForSaleSuccess,
    getCgvForSaleRequest,
    getCgvForSaleSuccess,
} from "../reducers/cgvSlice";
import {push} from 'connected-react-router';
import config from "../config";

function* getCgvForSaleSaga(action) {
    const successData = {id: action.payload};
    const user = yield select(selectUser);
    try {
        const saleData = yield call(fetch, `${config.gingolemApi}/sale/${action.payload}`);
        if (saleData.status !== 200) {
            yield call(dispatchErrorAccordingToStatus, saleData.status, "error.sale.data", cgvError);
            return
        }
        successData.sale = (yield saleData.json()).sale;
        if (user && user.sub) {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, `/cgv/${action.payload}`);
            if (resp.status === 200 || resp.status === 404) {
                successData.cgv = resp.status === 200 ? yield resp.json() : null;
            } else {
                yield call(dispatchErrorAccordingToStatus, resp.status, "error.cgv.cgu.data", cgvError);
                return
            }
        }
        yield put(getCgvForSaleSuccess(successData));
    } catch (e) {
        console.error({e});
        yield put(cgvError(e));
    }
}

function* createCgvForSaleSaga(action) {
    const user = yield select(selectUser);
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/cgv/${action.payload}/create`, true, {});
        if (resp.status === 200) {
            const cgv = yield resp.json();
            if (!cgv.error) {
                yield put(createCgvForSaleSuccess({cgv, id: action.payload}));
            } else {
                yield put(cgvError(cgv.error))
                yield put(push("/"))
            }
        } else {
            yield call(dispatchErrorAccordingToStatus, resp.status, "error.cgv.cgu.create", cgvError);
        }
    } catch (e) {
        console.error(e)
    }
}

function* watchCgvSaga() {
    yield takeLatest(getCgvForSaleRequest, getCgvForSaleSaga);
    yield takeLatest(createCgvForSaleRequest, createCgvForSaleSaga);
}

export default watchCgvSaga;