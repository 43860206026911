import React from "react";
import PropTypes from "prop-types";

const NavButton = (props) =>
    <button onClick={props.onClick}
            className={`relative flex flex-row items-center h-5 text-gray-600 hover:text-gray-800 border-l-4 border-transparent ${props.isActive ? '' : 'hover:'}border-red-500 pr-6`}>
        <span className="ml-2 text-sm tracking-wide truncate">{props.children}</span>
    </button>;

NavButton.propTypes = {
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
};

export default NavButton;
