import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import SignInForm from "../components/SignInForm";

import {
    signInRequest,
} from "../reducers/authSlice";
import HeaderLight from "../components/global/HeaderLight";

const SignInFormContainer = (props) => {

    const dispatch = useDispatch();

    return (
        <>
            <HeaderLight/>
            <SignInForm
                onChange={props.updateForm}
                signIn={() => dispatch(signInRequest())}
            />
        </>
    )
};

SignInFormContainer.propTypes = {
    updateForm: PropTypes.func,
};

export default SignInFormContainer