import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    success: null,
    message: null,
    tokenization: null,
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        resetPayment: state => {
            Object.assign(state, initialState);
        },
        initHostedTokenization: state => {
            state.loading = true;
            state.error = null;
            state.success = null;
            state.tokenization = null;
        },
        initHostedTokenizationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.tokenization = action.payload.tokenization
            state.success = null;
        },
        createPaymentRequest: state => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        createPaymentSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            if (action.payload.redirectUrl && action.payload.actionType === "REDIRECT") {
                if (action.payload.isTunnel) {
                    window.open(action.payload.redirectUrl, '_blank')?.focus();
                } else {
                    window.location.href = action.payload.redirectUrl;
                }
                state.success = null;
            } else {
                state.success = action.payload.success;
            }
        },
        checkPaymentStatus: state => {
            state.loading = true;
            state.error = null;
            state.success = null;
            state.message = null;
        },
        checkPaymentStatusSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = action.payload.success;
            state.message = action.payload.success;
        },
        checkUserCard: state => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        checkUserCardSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = action.payload.success;
        },
        resetMessage: state => {
            state.error = null;
            state.success = null;
        },
        paymentError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.success = null;
        }
    }
});

export const selectPaymentLoading = state => state.payment.loading;
export const selectPaymentError = state => state.payment.error;
export const selectPaymentSuccess = state => state.payment.success;
export const selectPaymentMessage = state => state.payment.message;
export const selectPaymentTokenization = state => state.payment.tokenization;

export const {
    createPaymentRequest,
    createPaymentSuccess,
    initHostedTokenization,
    initHostedTokenizationSuccess,
    paymentError,
    resetMessage,
    checkPaymentStatus,
    checkPaymentStatusSuccess,
    checkUserCard,
    checkUserCardSuccess,
    resetPayment,
} = paymentSlice.actions;

export default paymentSlice.reducer;
