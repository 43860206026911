import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle, faArrowCircleRight, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import config from "../config";
import {Link} from "react-router-dom";
import {isUserValid} from "../HelperFunctions";

const SignedInPage = (props) => {
    const isValid = isUserValid(props.user);
    return (
        <div className="flex flex-row flex-auto flex-shrink-0 antialiased text-gray-800 mt-4 mb-4">
            <div className="hidden lg:block lg:w-1/2 bg-gray-400 shadow-lg p-10 rounded-lg overflow-hidden">
                <div className={`${config.isMoniteur ? "bg-moniteur" : "bg-drouot-1"} bg-no-repeat bg-contain max-w-sm md:max-w-lg h-60 mb-8`}/>
                <h2 className="text-white text-3xl font-semibold"/>
                <div className="text-white text-xl mt-2 font-semibold mb-10"/>

            </div>
            <div className="w-full lg:w-2/3 bg-white p-8 shadow-lg p-8 lg:rounded-tr-lg lg:rounded-br-lg">
                <div className="rounded-t mb-0 py-6">
                    <div className="btn-wrapper text-center mt-6">
                        {
                            !isValid && <h6 className="text-blueGray-500 text-sm font-bold"><FormattedMessage id="label.incomplete.profile"/></h6>

                        }
                        <button type="button" onClick={props.continueAsToWebsite}
                                className={`${!isValid? "bg-red-700" : "bg-gray-700"} text-white px-6 py-2 rounded font-medium m-3 hover:bg-gray-800 transition duration-200 each-in-out`}>
                            <FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" />
                            {!isValid ? <FormattedMessage id="label.complete.as"/> : <FormattedMessage id="label.continue.as"/>} {props.user.email}
                        </button>
                    </div>
                    <hr className="mt-6 border-b-1 border-blueGray-300"/>

                    <div className="text-center mb-3">
                        <h6 className="text-blueGray-500 text-sm font-bold">ou</h6>
                    </div>
                    <div className="btn-wrapper text-center">

                        {isValid && <Link to="/profile/account"><button type="button"
                                className="mt-4 bg-gray-700 text-white px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out">
                            <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                            <FormattedMessage id="common.acceder.profil"/>
                        </button></Link>}
                        <button type="button" onClick={props.signOut}
                                className="mt-4 bg-gray-700 text-white px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out">
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>
                            <FormattedMessage id="mypass.menu.deconnexion"/>
                        </button>
                    </div>

                </div>




            </div>
        </div>
    )
};

SignedInPage.propTypes = {
    user: PropTypes.object,
    redirUri: PropTypes.string,
    signOut: PropTypes.func,
    continueAsToWebsite: PropTypes.func.isRequired,
};

export default SignedInPage;