import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    dataPerSale: {},
};

export const cgvSlice = createSlice({
    name: 'cgv',
    initialState,
    reducers: {
        getCgvForSaleRequest: state => {
            state.loading = true;
            state.error = null;
        },
        getCgvForSaleSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.dataPerSale[action.payload.id] = {
                cgv: action.payload.cgv,
                sale: action.payload.sale,
            }
        },
        createCgvForSaleRequest: state => {
            state.loading = true;
            state.error = null;
        },
        createCgvForSaleSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.dataPerSale[action.payload.id].cgv = action.payload.cgv;
        },
        resetMessage: state => {
            state.error = null;
        },
        cgvError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const selectLoading = state => state.cgv.loading;
export const selectError = state => state.cgv.error;
export const selectCgvForSale = (state, saleId) => state.cgv.dataPerSale[saleId] ? state.cgv.dataPerSale[saleId].cgv : null;
export const selectInfoForSale = (state, saleId) => state.cgv.dataPerSale[saleId] ? state.cgv.dataPerSale[saleId].sale : null;

export const {
    getCgvForSaleRequest,
    getCgvForSaleSuccess,
    createCgvForSaleRequest,
    createCgvForSaleSuccess,
    resetMessage,
    cgvError,
} = cgvSlice.actions;

export default cgvSlice.reducer;