import TextField from "@material-ui/core/TextField/TextField";
import React from "react";
import {FormattedMessage} from "react-intl";

const InnerTextField = (props, ref) => {
    return <TextField
        {...props}
        inputRef={ref}
        fullWidth
        variant="filled"
        size="small"
        name='phone'
    />
};

export default React.forwardRef(InnerTextField)